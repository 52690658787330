// import { useCallback } from "react";
import { SystemPageNames } from '@whop/niceurls/types';
import { useGlobalContainer } from './useGlobalContainer';
import { useCallback } from 'react';

export function useSystemUrlFn() {
  const inst = useGlobalContainer();
  return useCallback(
    (name: SystemPageNames) => {
      return inst.systemPageUrl(name);
    },
    [inst]
  );
}
