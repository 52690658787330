import React from 'react';
import { css } from '@emotion/core';

import { SystemBox, ResponsiveProp, useResolveSystemPropFn } from '@whop/system';
import { ThemeColorTones, ThemeFontWeights, AppThemeColorNames } from '../pkg.theme/types';
import { useTheme } from '../app.use/useTheme';
import { useResolveResponsiveFn } from '../app.base/responsive';
import { StyleProp } from '@whop/react/types';
import { SystemBoxAs, SystemBoxProps } from '@whop/system/types';

// @review(tone+weight) add to LinedText as well? (if usefull...)

// @review: make ResponsiveProp optional for size
type OptionallyResponsiveProp<T> = ResponsiveProp<T> | T;

interface TextProps {
  size?: ResponsiveProp<'small' | 'base' | 'large'>;
  color?: AppThemeColorNames;
  tone?: ThemeColorTones;
  weight?: ThemeFontWeights;
  align?: OptionallyResponsiveProp<'left' | 'right' | 'center'>;
  transform?: StyleProp['textTransform'];
  children: React.ReactNode;
}

/**
 * Provides convenient props such as `size` to set consistent text size with
 * responsive style.
 * @see https://seek-oss.github.io/braid-design-system/components/Text/ (inspired by)
 */
export function SystemText<T extends SystemBoxAs = 'span'>(
  props: Omit<SystemBoxProps<T>, 'children'> & TextProps
) {
  const { size, align, transform, tone, weight, ...pass } = props;

  const resolveSystemProp = useResolveSystemPropFn();
  const resolveResponsive = useResolveResponsiveFn();
  const theme = useTheme();

  // @todo handle w/out px + w/ rem
  const fontSize = size ? `${resolveSystemProp('textFontSizes', size)}px` : undefined;
  const color = props.color ? theme.colors[props.color] : tone && theme.colors[`tone_${tone}`];
  const fontWeight = weight && theme.fontWeights[weight];
  const textAlign = align && Array.isArray(align) ? resolveResponsive(align) : align;

  return (
    <SystemBox
      {...pass}
      css={css`
        font-size: ${fontSize};
        color: ${color ? color : ''};
        font-weight: ${fontWeight ? fontWeight : ''};
        text-align: ${textAlign ? textAlign : ''};
        text-transform: ${transform};
      `}
    />
  );
}

SystemText.defaultProps = { as: 'span' }; // in JSX it lead to typescript errors

export const Text = SystemText;
