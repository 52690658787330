/**
 * Whether is production from build time perspective.
 *
 * Can be used to branch code which should be run only on production,
 * or only on development/testing.
 */
export const IS_PRODUCTION_BUILD = process.env.NODE_ENV === 'production';
export const IS_DEVELOPMENT_BUILD = !IS_PRODUCTION_BUILD;

/**
 * Note: if its empty it fallbacks to same server.
 * @todo: make it work with ./bin/start
 */
export const API_SERVER_URL = process.env.RAZZLE_API_SERVER_URL || '';

/**
 * Whether or not fetch data from APIs only once (only for frontend development). This makes
 * HMR + suspense better.
 * More reasoning: endpoints are slow, frontend doesn't need fresh data (can reload page if it does).
 */
export const IS_FETCH_ONCE_ENABLED =
  process.env.NODE_ENV === 'development' && Boolean(process.env.RAZZLE_FETCH_ONLY_ONCE);
