import { asyncComponent } from '@whys/app/lib/pages';

import { PageLoader } from '../pkg.syncregistry/aliases';

function defRoute(path: string, loader: () => Promise<AnyInternalOnly>) {
  return {
    path,
    exact: true,
    component: asyncComponent({
      loader,
      Placeholder: PageLoader,
    }),
  };
}

export default [
  defRoute('/project', () => import('../tsx.app.starter/ProjectPage')),
  defRoute('/dev', () => import('../tsx.app.starter/DevelopmentPage')),
  defRoute('*', () => import('../pkg.niceurls/NiceUrlPage')),
  // defRoute('/', () => import('../tsx.app.starter/HomePage')),
  // defRoute('/cart', () => import('../tsx.app.cart/CartPage')),
  // defRoute('/login', () => import('../tsx.app.auth/LoginPage')),
  // defRoute('/profile', () => import('../tsx.app.auth/ProfilePage')),
  // defRoute('/test/category/:id', () => import('../tsx.app.category/CategoryPage')),
];
