function updateHTML() {
  return (initialHtml: string) => {
    // see the alongside source file (minify via e.g. https://javascript-minifier.com/)
    const scriptMinified =
      'function checkIE(){return function(){if("undefined"==typeof window)return!1;var n=function(n){var e=n.indexOf("MSIE ");if(e>0)return{isIE:!0,version:(n.substring(e+5,n.indexOf(".",e)),10)};if(n.indexOf("Trident/")>0){var i=n.indexOf("rv:");return{isIE:!0,version:(n.substring(i+3,n.indexOf(".",i)),10)}}return{isIE:!1,version:0}}(window.navigator.userAgent);return!!n.isIE&&n.version<=11}()}!function(){if(checkIE()){var n=document.getElementById("root"),e=document.getElementById("noiemsg");n&&(n.style.display="none"),e&&(e.style.display="block")}}();';
    const scriptHtml = `<script>${scriptMinified}</script>`;
    const bodyHtml = `<div id="noiemsg" style="display: none;">This browser is not supported, please use <a href="https://www.microsoft.com/windows/microsoft-edge">Microsoft Edge</a>.</iframe></div>`;

    let currentHtml = initialHtml;
    const headInjectAt = currentHtml.indexOf('<head>') + '<head>'.length;
    currentHtml = `${currentHtml.slice(0, headInjectAt)}${scriptHtml}${currentHtml.slice(
      headInjectAt
    )}`;
    const bodyInjectAt = currentHtml.indexOf('<body>') + '<body>'.length;
    currentHtml = `${currentHtml.slice(0, bodyInjectAt)}${bodyHtml}${currentHtml.slice(
      bodyInjectAt
    )}`;
    return currentHtml;
  };
}

export function setup() {
  return {
    updateHTML,
  };
}
