import { fetchJsonModule } from './types';

type CallableGeneric<T> = (...args: AnyGenerics[]) => T;

type LiteralOrCallable<T> = T | CallableGeneric<T>;
type HttpResourcesMap = { [k: string]: LiteralOrCallable<fetchJsonModule.ResourceInfo> };

/**
 * A TypeScript helper to define httpResources.
 * - it only helps to type method property to be one of enum (PUT, ...)
 */
export function defineHttpResources<T extends HttpResourcesMap>(info: T): T {
  return info;
}

export type HttpResource = fetchJsonModule.ResourceInfo;
export type HttpResourceCallable = CallableGeneric<fetchJsonModule.ResourceInfo>;
