import React from 'react';

import { GlobalRegistryType } from './types';
import { useInternalCtx } from './context';

type UseEnum = keyof GlobalRegistryType;

type GlobalRegistryProps<TName extends UseEnum> = {
  use: TName;
} & React.ComponentProps<GlobalRegistryType[TName]>;

export function GlobalRegistry<TName extends UseEnum>(props: GlobalRegistryProps<TName>) {
  const { use, ...pass } = props;
  const registry = useInternalCtx();
  const Component = registry.global[use];
  // @ts-ignore
  return <Component {...pass} />;
}
