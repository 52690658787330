import React from 'react';
import { createContext, useContext } from 'react';

import { AppProject } from '../app.types/app';

const ProjectContext = createContext<AppProject | null>(null);

export function __useProject(): AppProject {
  const value = useContext(ProjectContext);
  if (!value) {
    throw new Error('Please use Provider.');
  }
  return value;
}

export function ProjectProvider(props: { value: AppProject; children: React.ReactNode }) {
  return <ProjectContext.Provider value={props.value}>{props.children}</ProjectContext.Provider>;
}
