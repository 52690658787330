import React from 'react';
import { css } from '@emotion/core';

import { Text } from './Text';
import { Heading } from './Heading';
import { HeadingSection } from './structure';
import { SystemBox, useResolveSpaceFn } from '@whop/system';
import { ResponsiveValues, SystemBoxAs, SystemBoxProps, SystemThemeSpec } from '@whop/system/types';
import { StyleProp } from '@whop/react/types';
import { StackInline, StackInlineProps } from './StackInline';

type SizeProp = ResponsiveValues<keyof SystemThemeSpec['space']> | keyof SystemThemeSpec['space'];

export function VSpace(props: { size?: SizeProp }) {
  const { size = 1 } = props;
  const resolveSpace = useResolveSpaceFn();
  return (
    <div
      css={css`
        height: ${resolveSpace(size)};
      `}
    />
  );
}

export function HSpace(props: { size?: SizeProp }) {
  const { size = 1 } = props;
  const resolveSpace = useResolveSpaceFn();
  return (
    <span
      css={css`
        width: ${resolveSpace(size)};
      `}
    />
  );
}

export function Space(props: { size?: SizeProp }) {
  const { size = 1 } = props;
  const resolveSpace = useResolveSpaceFn();
  const resolvedWithUnit = resolveSpace(size);
  return (
    <div
      css={css`
        width: ${resolvedWithUnit};
        height: ${resolvedWithUnit};
      `}
    />
  );
}

/**
 * Presets SystemBox to be 1) display: flex 2) flex-direction: column
 *
 * It also accepts most common style props for "flex column": alignItems and justifyContent.
 */
function FlexColumn<T extends SystemBoxAs = 'div'>(
  props: SystemBoxProps<T> & {
    alignItems?: StyleProp['alignItems'];
    justifyContent?: StyleProp['justifyContent'];
  }
) {
  const { alignItems, justifyContent, ...pass } = props;
  return (
    <SystemBox
      css={css`
        display: flex;
        flex-direction: column;
        align-items: ${alignItems};
        justify-content: ${justifyContent};
      `}
      {...pass}
    />
  );
}

/**
 * Presets SystemBox to be display: flex (and implicitly also flex-direction: row)
 *
 * It also accepts most common style props for "flex row": alignItems, flexWrap and justifyContent.
 * It also presets the alignItems to center. @review is it good idea?
 */
function FlexRow<T extends SystemBoxAs = 'div'>(
  props: SystemBoxProps<T> & {
    alignItems?: StyleProp['alignItems'];
    justifyContent?: StyleProp['justifyContent'];
    flexWrap?: StyleProp['flexWrap'];
  }
) {
  const { alignItems = 'center', justifyContent, flexWrap, ...pass } = props;
  return (
    <SystemBox
      css={css`
        display: flex;
        align-items: ${alignItems};
        justify-content: ${justifyContent};
        flex-wrap: ${flexWrap};
      `}
      {...pass}
    />
  );
}

/**
 * @component_status experimental
 * Shows items in row (using flexbox) and adds a spacing between, customizable
 * via optional space prop.
 */
function FlexRowStack<T extends SystemBoxAs = 'div'>(
  props: StackInlineProps<T> & {
    alignItems?: StyleProp['alignItems'];
    justifyContent?: StyleProp['justifyContent'];
    flexWrap?: StyleProp['flexWrap'];
  }
) {
  const { alignItems = 'center', justifyContent, flexWrap, ...pass } = props;
  return (
    // @ts-ignore (UnreadableErrorMessage)
    <StackInline
      css={css`
        display: flex;
        align-items: ${alignItems};
        justify-content: ${justifyContent};
        flex-wrap: ${flexWrap};
      `}
      {...pass}
    />
  );
}

function FlexColumnStack<T extends SystemBoxAs = 'div'>(
  props: StackInlineProps<T> & {
    alignItems?: StyleProp['alignItems'];
    justifyContent?: StyleProp['justifyContent'];
  }
) {
  const { alignItems = 'center', justifyContent, flexWrap, ...pass } = props;
  return (
    // @ts-ignore (UnreadableErrorMessage)
    <StackInline
      css={css`
        display: flex;
        flex-direction: column;
        align-items: ${alignItems};
        justify-content: ${justifyContent};
      `}
      {...pass}
    />
  );
}

/**
 * Flex in contrast with FlexRow and FlexColumn has responsive flex-direction property,
 * passed as required `flexDirection` prop.
 */
function Flex<T extends SystemBoxAs = 'div'>(
  props: SystemBoxProps<T> & {
    flexDirection: ResponsiveValues<'row' | 'column'>;
    alignItems?: StyleProp['alignItems'];
    justifyContent?: StyleProp['justifyContent'];
    flexWrap?: StyleProp['flexWrap'];
  }
) {
  const { responsive, flexDirection, alignItems, justifyContent, flexWrap, ...pass } = props;
  return (
    <SystemBox
      css={css`
        display: flex;
        align-items: ${alignItems};
        justify-content: ${justifyContent};
        flex-wrap: ${flexWrap};
      `}
      responsive={{
        ...responsive,
        flexDirection,
      }}
      {...pass}
    />
  );
}

// Only global and very generic should be added here.
export const systemUI = {
  Box: SystemBox,
  Space,
  VSpace,
  HSpace,
  Text,
  Heading,
  Section: HeadingSection,
  FlexRow,
  FlexRowStack,
  FlexColumn,
  FlexColumnStack,
  Flex,
};
