import React from 'react';
import { SystemPropValues, SystemBoxAs, SystemBoxProps } from '@whop/system/types';

import { ul as SystemUl, ol as SystemOl } from './html';
import { css } from '@emotion/core';
import { SystemBox } from '@whop/system';

// @review: make ordered default + rename prop from boolean prop

export type StackListProps = {
  ordered?: boolean;
  space?: SystemPropValues['space'];
  orientation?: 'vertical' | 'horizontal';
  children: React.ReactNodeArray;
};

// @todo make consistent styles with html.li ?

/**
 * @see Stack component
 * Works alike Stack but automatically wraps each children in html.li
 * so you don't have to.
 */
export function StackList<T extends SystemBoxAs = typeof SystemUl>(
  props: Omit<SystemBoxProps<T>, 'children'> & StackListProps
) {
  const { children, space, ordered, orientation = 'vertical', ...pass } = props;
  const lastIdx = children.length - 1;
  const ListComponent = ordered ? SystemOl : SystemUl;
  return (
    <ListComponent
      css={css`
        display: flex;
        flex-wrap: wrap;
        flex-direction: ${orientation === 'vertical' ? 'column' : 'row'};
      `}
      {...pass}
    >
      {props.children.map((item, idx) => {
        return (
          <SystemBox
            as="li"
            key={idx}
            css={css`
              margin-top: 0;
              margin-left: 0;
            `}
            system={{
              marginBottom:
                orientation === 'vertical' ? (lastIdx === idx ? [0] : space ?? [1]) : space ?? [1],
              marginRight:
                orientation === 'horizontal' ? (lastIdx === idx ? [0] : space ?? [1]) : [0],
            }}
          >
            {item}
          </SystemBox>
        );
      })}
    </ListComponent>
  );
}
