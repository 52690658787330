import { useState, useEffect } from 'react';

// https://github.com/palmerhq/the-platform/blob/master/src/useMedia.tsx
const supportsMatchMedia =
  typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';

/**
 * Low level API for media match API.
 */
export function useMediaQuery(
  query: string,
  options: { defaultMatches: boolean; ssrMatchMedia?: (q: string) => { matches: boolean } }
): boolean {
  const [matches, setMatches] = useState(() => {
    if (options.ssrMatchMedia) {
      return options.ssrMatchMedia(query).matches;
    }
    return options.defaultMatches;
  });

  useEffect(() => {
    if (!supportsMatchMedia) {
      return;
    }

    const mediaQueryList = window.matchMedia(query);
    let active = true;

    const listener = () => {
      if (!active) {
        return;
      }

      if (mediaQueryList.matches) {
        setMatches(true);
      } else {
        setMatches(false);
      }
    };

    mediaQueryList.addListener(listener);
    setMatches(mediaQueryList.matches);

    return () => {
      active = false;
      mediaQueryList.removeListener(listener);
    };
  }, [query]);

  return matches;
}
