import React from 'react';
import { ContentCard } from './ContentCard';
import { css } from '@emotion/core';

type PopupCardProps = { children: React.ReactNode };

export const PopupCard = React.forwardRef<'div', PopupCardProps>((props, ref) => {
  // Note: extends ContentCard with background because its used on top of other content.
  return (
    <ContentCard
      passRef={ref}
      css={css`
        background: white;
      `}
    >
      {props.children}
    </ContentCard>
  );
});
