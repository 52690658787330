import React from 'react';
import {
  createPage as __createPage,
  createPageRoot as __createPageRoot,
} from '@whys/app/lib/pages';

import { ConcretePageContext, PageMetaModel, NiceUrlPageContext } from '../app.types/pages';
import { PageLoader } from '../pkg.syncregistry/aliases';
import Helmet from 'react-helmet';
import { asyncComponent } from '@whys/app/lib/pages';

export function defineLoadablePage(loader: AnyInternalOnly) {
  return asyncComponent({
    loader,
    Placeholder: PageLoader,
  });
}

/**
 * @deprecated use createEntityPage
 */
export function createSystemPage<T>(
  Component: React.ComponentType<T>,
  opts?: {
    Loader?: React.ComponentType<{}>;
    getInitialProps?: (ctx: ConcretePageContext) => Promise<T>;
    RootComponent?: React.ComponentType<AnyProtoTypeLater>;
  }
) {
  return __createPage({
    Component,
    ...opts,
  });
}

/**
 * Entity page handles showing entity detail. It's provided with id param.
 */
export function createEntityPage<T>(
  Component: React.ComponentType<T>,
  opts?: {
    Loader?: React.ComponentType<{}>;
    getInitialProps?: (ctx: ConcretePageContext) => Promise<T>;
    RootComponent?: React.ComponentType<AnyProtoTypeLater>;
  }
) {
  return __createPage({
    Component,
    ...opts,
  });
}

export function createSeoPage<T extends {}>(
  Component: React.ComponentType<T>,
  opts?: {
    getInitialProps?: (ctx: NiceUrlPageContext) => Promise<T & { __meta: PageMetaModel }>;
  }
): React.ComponentType<T> & {
  getInitialProps: (ctx: NiceUrlPageContext) => Promise<T & { __meta: PageMetaModel }>;
} {
  function SeoPage(props: T & { __meta: PageMetaModel }) {
    const meta = props.__meta || {};
    const { key, canonicalLink } = meta;

    return (
      <React.Fragment key={key}>
        <Helmet>
          <title>{meta.title}</title>
        </Helmet>
        <Helmet>
          <meta name="keywords" content={meta.keywords} />
        </Helmet>
        <Helmet>
          <meta name="description" content={meta.description} />
        </Helmet>
        {canonicalLink ? (
          <Helmet>
            <link rel="canonical" href={canonicalLink} />
          </Helmet>
        ) : null}
        <Component {...props} />
      </React.Fragment>
    );
  }
  return __createPage({ Component: SeoPage, Loader: PageLoader, ...opts });
}
