import { fetchJSON } from '@whys/fetch/lib/json';

import { ArgumentTypes } from '@whop/utils/types';
import { FetchJsonFn } from '@whop/containers/types';
import { FetchEnvType } from '@whop/app/types';

type Args = ArgumentTypes<FetchJsonFn>;

export async function loadFetchJson(opts: { fetchEnv: FetchEnvType }): Promise<FetchJsonFn> {
  const { fetchEnv } = opts;
  return (info: Args[0], data?: Args[1]) => {
    return fetchJSON({
      env: fetchEnv,
      method: info.method || 'GET',
      url: info.url,
      data,
    });
  };
}
