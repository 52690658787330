import { AppContainer } from '../app.types/state';

import { StarterContainer } from '../app.containers/StarterContainer';
// import { AppLanguageEnum } from '../app.types/app';

export function createStarterContainer(self: AppContainer) {
  const { project } = self.context;
  const initialState = {
    currentProject: project,
  };
  return new StarterContainer({
    appContainer: self,
    initialState,
  });
}
