import React from 'react';

import { LoaderInlineProps } from '../types.contracts/loader.inline';
import { CenteredLayout } from '../tsx.layouts/CenteredLayout';
import { PrimaryButtonProps } from '../tmp.solutions/primaryButtonUtils';
import { GlobalRegistry } from './GlobalRegistry';
import { useResolveSpaceFn } from '@whop/system';

/**
 * @overview Aliases for common component for more readable JSX.
 *
 * Add an alias for a component if its used in specific project code or many times.
 */

export function InlineLoader(props: LoaderInlineProps) {
  return <GlobalRegistry use="loader.inline" {...props} />;
}

export function PageLoader(props: {}) {
  return (
    <CenteredLayout>
      <div css={{ fontSize: 32 }}>
        <GlobalRegistry use="loader.page" delayShort={800} delayLong={1600} />
      </div>
    </CenteredLayout>
  );
}

export function PrimaryButton(props: PrimaryButtonProps) {
  return <GlobalRegistry use="btns.primary" {...props} />;
}

export function FakePrimaryButton(props: PrimaryButtonProps) {
  return <PrimaryButton onClick={() => {}} {...props} />;
}

export function WidePrimaryButton(props: PrimaryButtonProps) {
  const resolveSpace = useResolveSpaceFn();
  return (
    <PrimaryButton
      size="large"
      css={{ padding: `${resolveSpace(1)} ${resolveSpace(4)}` }}
      {...props}
    />
  );
}
