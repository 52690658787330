import React from 'react';
import { Trans } from '@lingui/react';
import { ContentEmpty } from './ContentEmpty';

/**
 * Purpose of EnsureItems is to tell user the list is empty.
 * Usage: wrap everywhere a list is rendered.
 */
export function EnsureItems(props: { children: AnyHowtoType; emptyMessage?: string }) {
  const { children } = props;
  const asArray = React.Children.toArray(children);
  if (asArray.length === 0) {
    return (
      props.emptyMessage ?? (
        <ContentEmpty>
          <Trans>No items.</Trans>
        </ContentEmpty>
      )
    );
  }
  return children;
}
