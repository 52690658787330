/**
 * Forces type-safety for enums (string unions).
 * - each enum value must have a matching value
 */
export function matchEach<TEnum extends string, TReturns>(
  value: TEnum,
  mapping: Record<TEnum, TReturns>
) {
  return mapping[value];
}

export function callMatched<TEnum extends string, TReturns>(
  value: TEnum,
  mapping: Record<TEnum, () => TReturns>
) {
  return mapping[value]();
}
function noop() {}
callMatched.noop = noop;
