import { Container } from '@whys/app/lib/state';
import { AppResourceContext } from '../app.types/state';
import { AsyncTaskCreator, definePlainTaskFromResource } from '../tmp.prototyping/asyncTasks';
import { httpResources } from '@whop/cart';
import { CartContainer } from './CartContainer';
import { CartBulkAddPayload } from '@whop/cart/types';

type LocalState = void;

type LocalProps = {
  resourceContext: AppResourceContext;
  cartContainer: CartContainer;
};

export class MultiInsertContainer extends Container<LocalState> {
  private state: LocalState;

  bulkAdd: AsyncTaskCreator<CartBulkAddPayload>;

  // maps variant id to quantity
  bulkAddState: Map<string, number>;

  constructor(private props: LocalProps) {
    super();
    const { resourceContext } = props;

    this.bulkAdd = definePlainTaskFromResource<CartBulkAddPayload>(httpResources.addManyItems, {
      taskId: 'bulkAdd',
      resourceContext,
    });
    this.bulkAddState = new Map();
  }

  prepareInsert(props: { variantId: string; quantity: number }) {
    this.bulkAddState.set(props.variantId, props.quantity);
  }

  undoInsert(props: { variantId: string }) {
    this.bulkAddState.delete(props.variantId);
  }

  multiInsertReady() {
    return Array.from(this.bulkAddState.values()).filter((val) => val !== 0).length > 1;
  }

  /**
   * Returns variant ids that were added
   */
  async commitMultiInsert(keepValues?: boolean): Promise<{ variantIds: string[] }> {
    const clearValues = !keepValues;
    let bulkAddPayload = [];
    for (const [variant, quantity] of this.bulkAddState.entries()) {
      bulkAddPayload.push({ variant, quantity });
    }
    const task = this.bulkAdd(bulkAddPayload);
    const result = await task.run();
    if (result.ok) {
      const variantIds = Array.from(this.bulkAddState.keys());
      if (clearValues) this.bulkAddState.clear();
      return { variantIds };
    } else {
      return { variantIds: [] };
    }
  }

  isVariantPrepared(variantId: string) {
    return this.bulkAddState.has(variantId);
  }

  resetAllState() {
    this.bulkAddState.clear();
  }
}
