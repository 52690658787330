import { ToastProvider as Provider, useToasts } from 'react-toast-notifications';
import { useMemo } from 'react';
import React from 'react';
import { ItemStatusEnum } from '@whop/core/types';
import { matchEach } from '@whop/functional/enums';

type ToastTypes = 'ok' | 'err' | 'warn' | 'info';
type AddMessageOptions = { autoDismiss?: boolean; duration?: 'short' | 'default' | 'long' };

/**
 * Can be used to declaratively display notifications - uses react-toast-notifications.
 * @todo add 3rd param (callback) to addToast - it takes in its ID - return it
 */
export function useNotifications() {
  const { addToast } = useToasts();
  return useMemo(() => {
    return {
      notify: (type: ToastTypes, msg: React.ReactNode, opts?: AddMessageOptions) => {
        const resolvedOpts = {
          duration: 'default',
          ...opts,
        };

        // NotePrototype: add this config to provider
        const dismissTimeout = {
          short: 2400,
          default: 4500,
          long: 6000,
        }[resolvedOpts.duration];

        const appearance =
          {
            ok: 'success',
            err: 'error',
            info: 'info',
            warn: 'warning',
          }[type] || 'info';

        addToast(
          msg,
          {
            appearance,
            autoDismissTimeout: dismissTimeout,
            autoDismiss: opts?.autoDismiss,
          }
          // (id: string) => console.log(id)
        );
      },
    };
  }, [addToast]);
}

export function resolveToastTypeFromStatus(status: ItemStatusEnum) {
  const resolvedToastType: ToastTypes = matchEach(status, {
    OK: 'ok',
    PART_OK: 'ok',
    INFO: 'info',
    ERROR: 'err',
    WARN: 'warn',
    UNKNOWN: 'info',
    INACTIVE: 'info',
  });
  return resolvedToastType;
}

export { Provider };
