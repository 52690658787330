import React from 'react';
import { SystemBox } from '@whop/system';
import { css } from '@emotion/core';

import { useAllocatedHeight } from '../app.use/useAllocatedHeight';

export function CenteredLayout(props: { children: React.ReactNode }) {
  const allocatedHeight = useAllocatedHeight();
  return (
    <SystemBox
      system={{ padding: [1, 2, 3] }}
      css={css`
        min-height: calc(100vh - ${allocatedHeight}px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
      `}
    >
      {props.children}
    </SystemBox>
  );
}
