import React from 'react';
import { Layout } from '../pkg.theme/types';
import { SideMenuLayout } from './SideMenuLayout';

export const getLayout = (layout: Layout) => {
  const { variant } = layout;

  const layoutMap = {
    default: (props: { children: React.ReactNode }) => <>{props.children}</>,
    sidemenu: SideMenuLayout,
  };

  return layoutMap[variant];
};
