import React from 'react';
import { css as classNameFromProps, cx } from 'emotion';

// NoteReview: style is useless, right?

// interface WithClassName {
//   className: string;
// }

// NoteReview: is it worth it to type className?
type AnyComponentWithClassName = keyof JSX.IntrinsicElements | React.ComponentType<AnyGenerics>;

export interface BoxProps {
  // Note(as): this is conventional naming e.g. <link as /> or style-components's as prop
  as?: AnyComponentWithClassName;
  el?: React.ReactElement;
  className?: string;
  // NotePrototype: not an object only
  css?: AnyProtoTypeLater;
  props?: object;
  style?: object;
  children?: React.ReactNode;

  [cssProps: string]: AnyProtoTypeLater;
}

export function Box({
  as = 'div',
  el,
  className,
  css,
  props,
  style,
  children,
  ...styleProps
}: BoxProps) {
  // NotePrototype: just an idea, test implementation/behavior (especially cloned elements className)
  if (el) {
    return React.cloneElement(
      el,
      {
        className: cx([
          (classNameFromProps as AnyProtoTypeLater)([css, styleProps, el.props.className]),
          className,
        ]),
        style,
        ...props,
      },
      children
    );
  }
  return React.createElement(
    as,
    {
      className: cx([(classNameFromProps as AnyProtoTypeLater)([css, styleProps]), className]),
      style,
      ...props,
    },
    children
  );
}
