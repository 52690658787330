//
// z-index values
//

export const APP_BAR_Z_INDEX = 1000;
export const MENU_Z_INDEX = 1100;
export const POPUP_Z_INDEX = 1200;
export const CART_ANIMATION_Z_INDEX = 1300;

//
// element ids
//

export const POPOVER_QUICK_SEARCH_ELEMENT_ID = 'popover-quick-search';

export const CART_ELEMENT_CLASS_NAME = 'whyshop-cart';

export const CART_DESKTOP_ELEMENT_CLASS_NAME = 'whyshop-cart-desktop';

export const ADD_CART_BUTTON = 'cart-btn';
