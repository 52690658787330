// @todo consider memoization
// @todo move elsewhere if useful

/**
 * Creates a selector function.
 */
export function defineSelector<TMap extends Record<keyof TMap, TMap[keyof TMap]>>(def: TMap) {
  return <TName extends keyof TMap>(name: TName): ReturnType<TMap[TName]> => {
    return def[name]();
  };
}

//
// alternative API >>>
//

export type SelectorFn<T extends {}> = <TKey extends keyof T>(k: TKey) => T[TKey];

type SelectorImplementation<T> = {
  [P in keyof T]: () => T[P];
};

export function defineSelectorAlt<TMap extends Record<keyof TMap, TMap[keyof TMap]>>(
  impl: SelectorImplementation<TMap>
) {
  return <TName extends keyof TMap>(name: TName): ReturnType<TMap[TName]> => {
    return impl[name]();
  };
}

//
// <<< alternative API
//
