import { useTheme } from '../app.use/useTheme';
import { MenuItemModel } from '@whop/menu/types';
import { SystemBox } from '@whop/system';
import { css } from 'emotion';
import React, { useRef, useState } from 'react';
import { usePathMatchesSomeBreadcrumbsFn } from '../app.use/usePageBreadcrumbs';
import { BasicPopper, PopperProps } from '../tsx.components/BasicPopper';
import { EnsureItems } from '../tsx.components/EnsureItems';
import { BlockLink } from '../tsx.components/links';
import { BasicImage } from '../tsx.utils.img/BasicImage';
import { html } from '../utils.styles/html';
import { systemUI } from '../utils.styles/system';
import { useTimers } from '@whop/react/timers';
import { DEFAULT_POPUP_OPEN_DELAY } from '@whop/category/constants';

function useSubcategoryItemCss() {
  return useTheme().override.globalStyles.subcategoryItem;
}

function SubcategoryItem(props: {
  item: MenuItemModel;
  onClick: () => void;
  imgPlaceholder?: JSX.Element;
}) {
  const { item } = props;
  const matchesBreadcrumbs = usePathMatchesSomeBreadcrumbsFn();
  const isActive = matchesBreadcrumbs(item.url);
  const theme = useTheme();
  const subcategoryItem = useSubcategoryItemCss();

  return (
    <BlockLink
      css={css`
        ${subcategoryItem}
      `}
      key={item.id}
      to={item.url}
      label={item.title}
      onClick={props.onClick}
    >
      <systemUI.FlexColumn
        css={css`
          padding: 0 20px;
        `}
        alignItems="center"
      >
        <BasicImage
          maxWidth={100}
          height={60}
          objectPosition="50% 50%"
          src={item.mainImages?.small || ''}
          alt={item.title}
          customPlaceholder={props.imgPlaceholder}
        />

        <span
          className="subcategory-title"
          css={css`
            text-align: center;
            ${isActive
              ? `
                border-bottom: 3px solid ${theme.colors.primary};
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
                `
              : ``}
          `}
        >
          {item.title}
        </span>
      </systemUI.FlexColumn>
    </BlockLink>
  );
}

function NavItem(props: {
  item: MenuItemModel;
  onClick: () => void;
  onMouseEnter: (item: MenuItemModel, hasSubcategories: boolean) => void;
  onMouseLeave: () => void;
}) {
  const { item } = props;

  const theme = useTheme();
  const matchesBreadcrumbs = usePathMatchesSomeBreadcrumbsFn();

  const hasSubcategories = item.children.length !== 0;
  const isActive = matchesBreadcrumbs(item.url);

  const handleMouseEnter = () => {
    props.onMouseEnter(item, hasSubcategories);
  };

  const handleMouseLeave = () => {
    props.onMouseLeave();
  };

  return (
    <SystemBox
      aria-label="category-menu-item"
      system={{ mX: [2], mY: [1] }}
      onClick={props.onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <BlockLink
        to={item.url}
        label={item.identifier}
        css={css`
          color: ${item.isSpecial ? theme.colors.secondary : ''};
          user-select: none;
          font-weight: bold;
          text-transform: uppercase;
          border-bottom: ${isActive
            ? `3px solid ${theme.colors.primary}`
            : '3px solid transparent'};
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;
          :hover {
            color: ${item.isSpecial ? theme.colors.secondary : ''};
            border-bottom: ${hasSubcategories
              ? `3px solid ${theme.colors.primary}`
              : `3px solid ${theme.colors.secondary}`};
            transition: ${hasSubcategories ? '400ms' : '0ms'};
          }
        `}
      >
        {item.title}
      </BlockLink>
    </SystemBox>
  );
}

/**
 * Wrapper which accepts children prop and conditionally renders a popup below
 */
function WithSubcategoriesPopup(props: {
  activeCategory: MenuItemModel;
  isOpen: boolean;
  onClose: () => void;
  onHolderMouseLeave: () => void;
  children: React.ReactNode;
  imgPlaceholder?: JSX.Element;
  placement?: PopperProps['placement'];
  customWidth?: string;
  fullspan?: boolean;
}) {
  const { customWidth = 0, fullspan = false } = props;
  const anchorEl = useRef<HTMLDivElement>(null);
  const popupWidth = (anchorEl.current && anchorEl.current.offsetWidth) || 0;
  const fallbackWidth = '70vw';

  return (
    <div
      aria-label="subcategories-popup-holder"
      ref={anchorEl}
      onMouseLeave={props.onHolderMouseLeave}
      css={css`
        width: ${fullspan ? '100%' : 'fit-content'};
        margin: 0 auto;
      `}
    >
      {props.children}
      <BasicPopper
        id="subcategories-popup"
        anchorEl={anchorEl.current}
        open={props.isOpen}
        onClose={props.onClose}
        placement={props.placement || 'bottom'}
      >
        <SystemBox
          aria-label="popup-content"
          css={css`
            width: ${customWidth || popupWidth || fallbackWidth};
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 100px));
            justify-content: center;
            gap: 10px;
          `}
        >
          <EnsureItems>
            {props.activeCategory.children.map((subCat) => {
              return (
                <SubcategoryItem
                  key={subCat.id}
                  item={subCat}
                  onClick={props.onClose}
                  imgPlaceholder={props.imgPlaceholder}
                />
              );
            })}
          </EnsureItems>
        </SystemBox>
      </BasicPopper>
    </div>
  );
}

const fallbackInitialCategory: MenuItemModel = {
  id: '',
  title: '',
  identifier: '',
  url: '',
  mainImages: {},
  children: [],
};

export interface InjectedCategoryMenuProps {
  onCategoryMouseEnter: (item: MenuItemModel, hasSubcategories: boolean) => void;
  onCategoryMouseLeave: () => void;
  forceClose: () => void;
}

interface CategoryMenu {
  menuItems: MenuItemModel[];
  openDelay?: number;
  itemImagePlaceholder?: JSX.Element;
}

type CategoryMenuWrapper = Omit<CategoryMenu, 'menuItems'> & {
  activeMenuItem: MenuItemModel;
  placement?: PopperProps['placement'];
  customWidth?: string;
  fullspan?: boolean;
  children(props: InjectedCategoryMenuProps): JSX.Element;
};

export function PopupCategoryMenuWrapper(props: CategoryMenuWrapper) {
  const { activeMenuItem, openDelay = DEFAULT_POPUP_OPEN_DELAY } = props;

  const timers = useTimers<'delayed-open' | 'delayed-category-change'>();

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState<MenuItemModel>(
    activeMenuItem || fallbackInitialCategory
  );

  // Readability helpers
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const clearPopupTimer = () => {
    timers.clearById('delayed-open');
  };

  const clearContentTimer = () => {
    timers.clearById('delayed-category-change');
  };

  const forceClose = () => {
    clearPopupTimer();
    clearContentTimer();
    isPopupOpen && closePopup();
  };

  // Base functions
  const openPopupLater = () => {
    timers.set(
      {
        id: 'delayed-open',
        timeoutMs: openDelay,
      },
      openPopup
    );
  };

  const setActiveCategoryLater = (item: MenuItemModel) => {
    timers.set(
      {
        id: 'delayed-category-change',
        timeoutMs: openDelay,
      },
      () => setActiveCategory(item)
    );
  };

  const onCategoryMouseEnter = (item: MenuItemModel, hasSubcategories: boolean) => {
    if (hasSubcategories) {
      isPopupOpen ? setActiveCategoryLater(item) : setActiveCategory(item);
      !isPopupOpen && openPopupLater();
    } else {
      forceClose();
    }
  };

  const onCategoryMouseLeave = () => {
    clearContentTimer();
  };

  return (
    <WithSubcategoriesPopup
      isOpen={isPopupOpen}
      onClose={forceClose}
      activeCategory={activeCategory}
      onHolderMouseLeave={forceClose}
      imgPlaceholder={props.itemImagePlaceholder}
      customWidth={props.customWidth}
      placement={props.placement}
      fullspan={props.fullspan}
    >
      {props.children({
        forceClose,
        onCategoryMouseEnter,
        onCategoryMouseLeave,
      })}
    </WithSubcategoriesPopup>
  );
}

/**
 * Categories menu w/ popup subcategories
 * @param openDelay how long to wait before category gets opened on hover
 * @todo maybe read the delay from config? [initial data/runtime cfg]
 */
export function PopupCategoryMenu(props: CategoryMenu) {
  return (
    <PopupCategoryMenuWrapper
      activeMenuItem={props.menuItems[0]}
      itemImagePlaceholder={props.itemImagePlaceholder}
      openDelay={props.openDelay}
    >
      {(injectedProps: InjectedCategoryMenuProps) => {
        return (
          <nav>
            <html.ul
              css={css`
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
              `}
            >
              <EnsureItems>
                {props.menuItems.map((menuItem) => {
                  return (
                    <html.li key={menuItem.id}>
                      <NavItem
                        item={menuItem}
                        onMouseEnter={injectedProps.onCategoryMouseEnter}
                        onMouseLeave={injectedProps.onCategoryMouseLeave}
                        onClick={injectedProps.forceClose}
                      />
                    </html.li>
                  );
                })}
              </EnsureItems>
            </html.ul>
          </nav>
        );
      }}
    </PopupCategoryMenuWrapper>
  );
}
