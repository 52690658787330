// import React, { useEffect, useState } from 'react';
import React from 'react';
import { createContext, useContext } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import { AppTheme } from '../pkg.theme/types';
import { defaultTheme } from '../pkg.theme';
// import { createMuiThemeFromAppTheme } from '../project.base/model';
import { useProject } from '../app.use/useProject';
import { SsrMatchMediaFn } from '../app.types/state';

const ThemeContext = createContext<AppTheme>(defaultTheme);

export function __useTheme(): AppTheme {
  return useContext(ThemeContext);
}

export function ThemeProvider(props: {
  value: AppTheme;
  children: React.ReactNode;
  ssrMatchMedia?: SsrMatchMediaFn;
}) {
  const { ssrMatchMedia } = props;
  const theme = props.value;
  const project = useProject();

  // const [muiTheme, setMuiTheme] = useState(() => {
  //   return createMuiThemeFromAppTheme(theme);
  // });

  // useEffect(() => {
  //   setMuiTheme(createMuiThemeFromAppTheme(theme));
  // }, [theme]);

  // useEffect(() => {
  //   setMuiTheme(createMuiThemeFromAppTheme(project.theme));
  // }, [project]);

  // Note: this is probably unnecessary, because we don't use useMediaQuery from material-ui
  // and material-ui doesn't use it itself.
  const muiThemeFinal = {
    ...project.muiTheme,
    props: {
      MuiUseMediaQuery: { ssrMatchMedia },
      ...(project.theme.muiConfig && { ...project.theme.muiConfig.props }),
    },
  };

  return (
    <ThemeContext.Provider value={theme}>
      <MuiThemeProvider theme={muiThemeFinal}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}
