import { useLocation } from 'react-router-dom';
import { BreadcrumbsModel } from '@whop/niceurls/types';
import { useManualEffect, useForceUpdate, useHasMounted } from '@whop/react/component';
import { usePagesContext } from '../app.context/pagesContext';
import { useResourceLive } from '../pkg.resources/useResourceLive';

export function usePageBreadcrumbs(): BreadcrumbsModel[] {
  const location = useLocation();
  const hasMounted = useHasMounted();
  const forceUpdate = useForceUpdate();
  const { niceUrlInfos } = usePagesContext();
  const niceUrlResource = niceUrlInfos(location.pathname);
  const [niceUrl] = useResourceLive(niceUrlResource);

  const refetchResource = async () => {
    await niceUrlResource.getOrFetch();
    forceUpdate();
  };

  useManualEffect(() => {
    hasMounted && refetchResource();
  }, [niceUrl]);

  const pageInfo = niceUrlInfos(location.pathname).select();
  if (!pageInfo) {
    return [];
  }
  if (pageInfo.status === 'ok') {
    return pageInfo.value.breadcrumbs;
  }
  return [];
}

export function usePathMatchesSomeBreadcrumbsFn(): (pathname: string) => boolean {
  const breadcrumbs = usePageBreadcrumbs();
  return (pathname: string) => {
    return breadcrumbs.some((item) => item.url === pathname);
  };
}
