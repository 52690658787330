import { Container } from '@whys/app/lib/state';
import {
  AppResourceContext,
  fetchJsonAsBool,
  definePlainListResource,
} from '../tmp.prototyping/appLevelResources';
import { PlainResource } from '@whop/resources/types';
import { httpResources } from '@whop/product';
import { ProductItemModel } from '@whop/product/types';
import { mapProductItem } from '@whop/product/mapping';
import { ConfigContainerType } from './ConfigContainer';

type LocalState = { favoriteProducts: ProductItemModel[]; favoritesCount: number };

type LocalProps = {
  resourceContext: AppResourceContext;
  configContainer: ConfigContainerType;
  initialFavoritesCount?: number;
};

export class FavoritesContainer extends Container<LocalState> {
  private state: LocalState;
  private __tmpFaved: Set<string> = new Set();
  private __tmpUnfaved: Set<string> = new Set();
  private favProductsResource: PlainResource<ProductItemModel[]>;

  constructor(private props: LocalProps) {
    super();

    const { resourceContext } = props;

    this.state = { favoriteProducts: [], favoritesCount: props.initialFavoritesCount || 0 };

    //Note(Tomas): pagination could be implemented but a simple list is good enough for now
    this.favProductsResource = definePlainListResource(httpResources.listFavoriteProducts, {
      mapItem: mapProductItem,
      resourceContext,
    });
  }

  getFavoriteProducts(): ProductItemModel[] {
    return this.state.favoriteProducts;
  }

  // async loadFavoriteProducts() {
  //   const items = await this.favProductsResource.getOrFetch();
  //   this.setState({ favoriteProducts: items, favoritesCount: items.length });
  // }

  async reloadFavoriteProducts() {
    const items = await this.favProductsResource.refetch();
    this.setState({ favoriteProducts: items, favoritesCount: items.length });
  }

  async addFavorite(item: ProductItemModel): Promise<boolean> {
    const { id } = item;

    this.__tmpFaved.add(id);
    this.__tmpUnfaved.delete(id);
    this.setState({
      //Note(Tomas): this is kind of pointless since we reload from API on mount
      favoriteProducts: [...this.state.favoriteProducts, item],
      favoritesCount: this.state.favoritesCount + 1,
    });

    return await fetchJsonAsBool(httpResources.favoriteProduct(id), {
      resourceContext: this.props.resourceContext,
    });
  }

  async removeFavorite(item: ProductItemModel): Promise<boolean> {
    const { id } = item;

    this.__tmpUnfaved.add(id);
    this.__tmpFaved.delete(id);
    this.setState({
      favoriteProducts: this.state.favoriteProducts.filter((item) => item.id !== id),
      favoritesCount: this.state.favoritesCount - 1,
    });

    return await fetchJsonAsBool(httpResources.unfavoriteProduct(id), {
      resourceContext: this.props.resourceContext,
    });
  }

  isFavorite(item: ProductItemModel) {
    const { id } = item;
    // Note: the temporary state has preference over API values
    if (this.__tmpFaved.has(id)) {
      return true;
    }
    if (this.__tmpUnfaved.has(id)) {
      return false;
    }
    return item.is.favorite;
  }
}

export type FavoritesContainerType = FavoritesContainer;
