import React, { CSSProperties } from 'react';

import { SystemBox } from '@whop/system';
import { __headingComponents } from '../utils.styles/html';
import { css } from '@emotion/core';
import { HeadingLevelEnum } from '../app.types/styles';
import { useHeadingLevel } from './structure';
import { SystemBoxAs, SystemBoxProps } from '@whop/system/types';
import { useResolveResponsiveFn } from '../app.base/responsive';

// @review add align prop or just make possible to pass anything to SystemBox?
// @review is scale good idea? isn't confusing? doesn't it make inconsistent heading font sizes?
// @todo handle line-height somehow
// @todo make type def exclusive scale/size
// @todo make it system-wise (remove SystemBoxAltProps<any>)
type HeadingProps = {
  level?: HeadingLevelEnum;
  // @todo: make responsive
  scale?: 'small' | 'base' | 'large';
  // @todo: make responsive, @review: is this correct way to type CSS values?
  align?: CSSProperties['textAlign'];
  // @todo: make responsive
  size?: 0 | 1 | 2 | 3 | 4;
  overrideFontSize?: CSSProperties['fontSize'];
  children: React.ReactNode;
};

const levelToComponent = {
  1: __headingComponents.h1,
  2: __headingComponents.h2,
  3: __headingComponents.h3,
  4: __headingComponents.h4,
  5: __headingComponents.h5,
  6: __headingComponents.h6,
};

// Note: move to theme
const textBase = 16;
const levelScales = {
  // h1
  'h1.small': 42 - 4,
  'h1.base': 42,
  'h1.large': 42 + 4,
  // h2
  'h2.small': 28 - 4,
  'h2.base': 28,
  'h2.large': 28 + 4,
  // h3
  'h3.small': 21 - 4,
  'h3.base': 21,
  'h3.large': 21 + 4,
  // h4
  'h4.small': textBase - 4,
  'h4.base': textBase,
  'h4.large': textBase + 4,
  // h5
  'h5.small': textBase - 4,
  'h5.base': textBase,
  'h5.large': textBase + 4,
  // h6
  'h6.small': textBase - 4,
  'h6.base': textBase,
  'h6.large': textBase + 4,
};

// Note: this is just a prototype, but 0 should be zero size relative to body text
// 0-4
const headingAbsSizes = [
  // base text
  textBase,
  // h4
  18,
  // h3
  21,
  // h2,
  28,
  // h1
  42,
];

/**
 * Heading handles structural level and size of titles.
 *
 * - use within HeadingSection to automatically set current level
 * - use level prop to override the automatical level
 * - use either scale or size prop to change heading font size relatively or absolutely
 *
 * Props:
 * - size: 0-4; 0 is base text, 1 is smallest and 4 is largest heading size
 */
export function Heading<T extends SystemBoxAs = 'h1'>(props: SystemBoxProps<T> & HeadingProps) {
  const { level, align, size, overrideFontSize, scale, system, ...pass } = props;
  const contextLevel = useHeadingLevel();
  const resolveResponsive = useResolveResponsiveFn();

  const resolvedLevel = level || contextLevel;
  const as = levelToComponent[resolvedLevel];

  const fontSize = (() => {
    if (overrideFontSize) {
      return overrideFontSize;
    }
    if (size === undefined) {
      return levelScales[`h${resolvedLevel}.${scale || resolveResponsive(['small', 'base'])}`];
    }
    return headingAbsSizes[size];
  })();

  return (
    <SystemBox
      as={as}
      css={css`
        font-size: ${fontSize}px;
        text-align: ${align};
      `}
      system={{ fontFamily: 'headings', ...system }}
      {...pass}
    />
  );
}
