import { PromiseManager } from '@whop/resources/types';
import { IS_FETCH_ONCE_ENABLED } from '../app.constants/env';

/**
 * @overview This is PromiseManager implementation for BROWSER.
 *
 * It can have cache on module level (localPromiseCache, localStateCache) because the app runs only
 * once for one user. This allows HMR to work without refetching all the data.
 *
 * Server must have a different implementation, because it cannot share same resources on different
 * requests (possibly different users).
 */

let localPromiseCache = new Map<string, Promise<AnyGenerics>>();
let localStateCache = new Map<string, 'pending' | 'idle'>();

export function createPromiseManager(): PromiseManager {
  function manageResolve<T>(
    resolve: () => Promise<T>,
    options: { key: string; canReusePromises: boolean }
  ) {
    const { key, canReusePromises } = options;

    // step: If a promise for given key already exists, return the promise (thus "promise manager").
    // This handles concurrent resource calls for same key to be handled by only one promise.
    const existing = localPromiseCache.get(key);

    if (existing && canReusePromises && IS_FETCH_ONCE_ENABLED) {
      return existing;
    }
    if (existing && localStateCache.get(key) === 'pending') {
      return existing;
    }

    // step: If promise doesn't exist yet, create it and store it in context to work with it in later calls.
    const promise = resolve();
    localPromiseCache.set(key, promise);
    localStateCache.set(key, 'pending');

    // step: If given resource cannot be cached, add a callback when its succesfully resolved to
    // remove it from the local cache. Note that this will still return exactly one promise for multiple
    // concurrent calls to resolve of the same resource (by key). But once its resolved, it can be re-resolved
    // again.
    // step: If resource promise was rejected, never cache it
    const onOk = () => {
      localStateCache.set(key, 'idle');
      if (!canReusePromises && !IS_FETCH_ONCE_ENABLED) {
        localPromiseCache.delete(key);
      }
    };
    const onErr = () => {
      localStateCache.set(key, 'idle');
      localPromiseCache.delete(key);
    };
    promise.then(onOk, onErr);

    // step: return the promise (do not await it) AS-IS
    return promise;
  }
  return { manageResolve };
}
