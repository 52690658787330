import React from 'react';
import MuiIconButton, { IconButtonProps } from '@material-ui/core/IconButton';

interface LocalProps extends IconButtonProps {
  label: string;
}

export function IconButton(props: LocalProps) {
  const { label, ...pass } = props;
  return <MuiIconButton aria-label={label} title={label} {...pass} />;
}
