// peer dependencies
import React from 'react';
import { cache } from 'emotion';
import { CacheProvider } from '@emotion/core';

// own dependencies
import { renderStylesToString } from 'emotion-server';

type Context = null;

function EmotionPlugin(props: { children: React.ReactNode; ctx: React.Context<Context> }) {
  return React.createElement(CacheProvider, { value: cache }, props.children);
}

function updateHTML() {
  return (html: string) => {
    return renderStylesToString(html);
  };
}

export function setup() {
  return {
    Provider: EmotionPlugin,
    updateHTML,
  };
}
