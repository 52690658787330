import { setupI18n } from '@lingui/core';
import { AppProject } from '../app.types/app';

export async function loadI18n(options: { language: string; project: AppProject }) {
  const {
    language,
    project: {
      config: { localeProjectId },
    },
  } = options;

  let languageCatalog;
  try {
    languageCatalog = (
      await import(`../../locale/projects/${localeProjectId}/${language}/messages.js`)
    ).default;
  } catch (e) {
    console.error(e);
    // @ts-ignore
    const enCatalog = (await import(`../../locale/projects/starter/en/messages.js`)).default;
    return setupI18n({ catalogs: { en: enCatalog }, language: 'en' });
  }

  const catalogs = { [language]: languageCatalog };
  return setupI18n({ catalogs, language });
}
