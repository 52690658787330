import * as React from 'react';
import { createContext, useContext } from 'react';

import { AppContainer, ResetAppFn } from '../app.types/state';

type FrameworkValue = {
  appContainer: AppContainer;
  onResetApp: ResetAppFn;
};

const ctx = createContext<FrameworkValue | null>(null);

export function FrameworkProvider(props: { children: React.ReactNode; value: FrameworkValue }) {
  return <ctx.Provider value={props.value}>{props.children}</ctx.Provider>;
}

function useFrameworkCtx() {
  const value = useContext(ctx);
  if (!value) {
    throw new Error('Please use FrameworkProvider.');
  }
  return value;
}

export function useAppContainer(): AppContainer {
  const value = useFrameworkCtx();
  return value.appContainer;
}

export function useOnResetApp() {
  const value = useFrameworkCtx();
  return value.onResetApp;
}
