import React from 'react';
import { css } from '@emotion/core';

import { SystemBox, useResolveSystemPropFn } from '@whop/system';
import { SystemPropValues, SystemBoxProps, SystemBoxAs } from '@whop/system/types';

export type StackInlineProps<T extends SystemBoxAs = 'div'> = Omit<
  SystemBoxProps<T>,
  'children'
> & {
  space?: SystemPropValues['space'];
  children: React.ReactNode[];
};

/**
 * @see https://github.com/seek-oss/braid-design-system/blob/master/lib/components/Inline/Inline.tsx
 */
export function StackInline<T extends SystemBoxAs = 'div'>(props: StackInlineProps<T>) {
  const resolveSystemProp = useResolveSystemPropFn();
  const { children, space, ...pass } = props;
  const spaceProp = space ?? [1];
  const spaceValue = resolveSystemProp('space', spaceProp);
  const negativeSpaceValue = spaceValue * -1;
  return (
    <SystemBox
      css={css`
        display: flex;
        flex-wrap: wrap;
        margin-top: ${negativeSpaceValue}px;
        margin-left: ${negativeSpaceValue}px;
      `}
      {...pass}
    >
      {props.children.map((item, idx) => {
        return (
          <SystemBox key={idx} system={{ marginLeft: spaceProp, marginTop: spaceProp }}>
            {item}
          </SystemBox>
        );
      })}
    </SystemBox>
  );
}
