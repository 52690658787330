import { useContext } from 'react';
import { I18nType } from '../app.types/npm';
import { LinguiContext } from '../app.context/lingui';

// Lingui as of now has no hook API, so we have to create own context.
// It'll work without the context (see https://github.com/lingui/js-lingui/issues/390#issuecomment-438173038)
// but its not released yet.

export function useLingui(): I18nType {
  const i18n = useContext(LinguiContext);
  if (!i18n) {
    throw new Error('Please use LinguiProvider.');
  }
  return i18n;
}
