import React from 'react';
import { css } from '@emotion/core';

/* original source from https://codepen.io/xwildeyes/pen/KpqVzN */

function DotItem(props: {}) {
  return (
    <span
      css={css`
        animation-name: LoadingDots__blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
      `}
    >
      .
    </span>
  );
}

export function DotAnimation(props: { color?: string }) {
  return (
    <span
      css={css`
        cursor: help;
        color: ${props.color};

        @keyframes LoadingDots__blink {
          0% {
            opacity: 0.2;
          }
          20% {
            opacity: 1;
          }
          100% {
            opacity: 0.2;
          }
        }

        span:nth-child(2) {
          animation-delay: 0.2s;
        }

        span:nth-child(3) {
          animation-delay: 0.4s;
        }
      `}
    >
      <DotItem />
      <DotItem />
      <DotItem />
    </span>
  );
}
