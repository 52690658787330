import jsonStableStringify from 'json-stable-stringify';

/**
 * A helper like JSON.stringify that for same deep JSON-serializable value
 * returns same string.
 *
 * It should be used on small objects to get stable (e.g. cache) keys.
 */
export function stringifyToStableJson(value: object): string {
  return jsonStableStringify(value);
}
