import { Container } from '@whys/app/lib/state';
import { AppResourceContext } from '../app.types/state';
import { AsyncRegistryShape } from '../pkg.asyncregistry/types';

type LocalState = void;

type LocalProps = {
  resourceContext: AppResourceContext;
  registry: AsyncRegistryShape;
};

export class RegistryContainer extends Container<LocalState> {
  private state: LocalState;

  constructor(private props: LocalProps) {
    super();
    // const { resourceContext } = props;
  }

  selectDeclaredComponent<T extends keyof AsyncRegistryShape>(name: T): AsyncRegistryShape[T] {
    const { registry } = this.props;
    return registry[name];
  }
}
