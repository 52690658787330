import { Container } from '@whys/app/lib/state';
import { createFlatCache } from '../app.utils/flatCache';
import { FetchJsonFn } from '@whop/containers/types';
import { AppContainer, AppContext } from '../app.types/state';
import { FlatCache, AppProject } from '../app.types/app';
import { DjreactState, CacheType } from '@whop/app/types';

type LocalState = {
  currentProject: AppProject;
  // configurableTheme: AppTheme;
  // currentLanguage: AppLanguageEnum;
};

type LocalProps = {
  initialState: LocalState;
  appContainer: AppContainer;
};

/**
 * StarterContainer defines access to project/theme data and metadata.
 *
 * For global/app-wise stuff use GlobalContainer.
 */
export class StarterContainer extends Container<LocalState> {
  private state: LocalState;
  private props: LocalProps;

  djreactState: DjreactState;
  appContext: AppContext;

  __internalApi: {
    appContainer: AppContainer;
    fetchJson: FetchJsonFn;
  };

  constructor(props: LocalProps) {
    super();

    const { appContainer } = props;

    const appContext = appContainer.context;

    this.props = props;

    this.djreactState = appContext.djreactState;
    this.appContext = appContext;

    this.__internalApi = {
      appContainer: props.appContainer,
      fetchJson: appContext.fetchJson,
    };
    this.state = { ...props.initialState };
  }

  selectProject(): AppProject {
    return this.state.currentProject;
  }

  // selectProjectId(): ProjectIdNames {
  //   return this.props.initialData.site.project_id;
  // }

  // selectTheme(): AppTheme {
  //   return this.state.currentProject.theme;
  // }

  // selectLanguage(): AppLanguageEnum {
  //   return this.state.currentLanguage;
  // }

  // selectDefaultLanguage() {
  //   return this.props.defaultLanguage;
  // }

  // selectDefaultProject() {
  //   return this.props.initialData.site.project_id;
  // }

  selectCache<T>(name: string): CacheType<T> {
    return this.appContext.appCache.getOrCreateCache<T>(name);
  }

  selectFlatCache<T, TD = T>(key: string): FlatCache<T, TD> {
    const cache = this.selectCache<T>('app.flat');
    return createFlatCache<T, TD>(cache, key);
  }
}
