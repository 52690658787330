import { stringify, parse } from '@whop/web/querystring';

/**
 * This module provides querystring-related low level utils applied for whyshop API.
 *
 * @todo move to @whop/resources ?
 */

const sameOptions = {
  arrayFormat: 'comma',
  encode: false,
} as const;

export function stringifyQueryParams(params: object): string {
  return stringify(params, sameOptions);
}

export function mergeQueryParamsWithQs(qs: string, params: object): string {
  const parsed = parseQueryParams(qs);
  return stringifyQueryParams({ ...parsed, ...params });
}

export function parseQueryParams(qs: string): object {
  return parse(qs, sameOptions);
}
