import { PlainResource } from '@whop/resources/types';
import { useManualEffect, useDidMount } from '@whop/react/component';
import { useState, useRef } from 'react';

/**
 * @overview just an idea, doesn't work
 */

type LocalResourceStates = 'initial' | 'loading' | 'loaded';

function mapResourceStatesLocally<T>(resource: PlainResource<T>): LocalResourceStates {
  if (resource.is('loaded')) {
    return 'loaded';
  }
  if (resource.is('loading')) {
    return 'loading';
  }
  return 'initial';
}

/**
 * A helper hook API for resources that are either lazy resources or resources that are being refetched.
 *
 * Lazy resource is not pre-loaded (that also means they're NOT server-rendered)
 * so they must be lazily-loaded on component mount.
 *
 * Refetched resource is typically a list and has to be refetched when item is added/removed/changed.
 */
export function useResourceLive<T>(
  resource: PlainResource<T>,
  options?: { refetchOnMount?: boolean }
): [T, LocalResourceStates] {
  const { refetchOnMount = false } = options || {};

  const [resourceData, setResourceData] = useState(resource.select());
  const isMountedRef = useRef(false);

  const subscribeToResourceChanges = () => {
    const unsubscribe = resource.subscribe((updates) => {
      // condition: set the data only if the component that uses this hook is still mounted (this can happen when
      // data loading takes seconds and user navigates out of page where the component is placed). This is an extra check,
      // because we actually unsubscribe on unmount.
      if (isMountedRef.current) {
        setResourceData(updates);
      }
    });
    return unsubscribe;
  };

  // step: on mount + on resource change (when its parametrized it changes the whole "resource" argument)
  // subscribe or re-subscribe to changes
  useManualEffect(() => {
    // note: always fetch if not already loaded
    if (resource.is('idle')) {
      resource.fetch();
    }
    return subscribeToResourceChanges();
  }, [resource]);

  useDidMount(() => {
    isMountedRef.current = true;
    if (refetchOnMount) {
      resource.refetch();
    }
    return () => {
      isMountedRef.current = false;
    };
  });

  return [resourceData, mapResourceStatesLocally(resource)];
}
