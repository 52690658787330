import { renderClient } from '@whys/app/lib/client';

const appId = 'starter';
const { createClientConfig } = require(`@app/${appId}/src/app.client/setupClient`);

renderClient(createClientConfig());

// @ts-ignore (.hot)
if (module.hot) {
  // @ts-ignore (.hot)
  module.hot.accept();
}
