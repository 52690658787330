import { css } from '@emotion/core';
import React from 'react';
import { SystemBox } from '@whop/system';
import { SystemBoxAs, SystemBoxProps } from '@whop/system/types';

export function ContentCard<T extends SystemBoxAs>(props: SystemBoxProps<T>) {
  return (
    <SystemBox
      system={{ padding: [1] }}
      css={css`
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

        transition: box-shadow 0.25s;
        border-radius: 4px;
        :hover {
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
        }
        box-sizing: border-box;
      `}
      {...props}
    />
  );
}

ContentCard.defaultProps = { as: 'div' };
