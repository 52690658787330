export class ResourceFetchError extends Error {
  constructor(options: { resourceName: string; response?: Response }) {
    const { response } = options;
    const statusStr = `http status: ${(response && response.status) || '?'}`;
    const message = `fetching "${options.resourceName}" failed (${statusStr})`;
    super(message);

    this.name = 'ResourceFetchError';
  }
}
