import React from 'react';
import {
  Link as LinkInternalImpl,
  LinkProps,
  NavLink as NavLinkInternalImpl,
  match as MatchType,
} from 'react-router-dom';
import { useTheme } from '../app.use/useTheme';
import { OutlineButton } from '../tsx.design/OutlineButton';
import { SystemPageNames } from '@whop/niceurls/types';
import { useSystemUrlFn } from '../app.use/useGetSystemUrl';
import { HtmlTagComponentProps, StyleProp } from '@whop/react/types';
import { usePagesContext } from '../app.context/pagesContext';
import { useTimers } from '@whop/react/timers';
import { SerializedStyles } from '@emotion/serialize';

function useGlobalStyles() {
  return useTheme().override.globalStyles;
}

/**
 *
 */

interface BaseNavLinkProps extends LinkProps {
  to: string;
  forceIsActive?: boolean;
  prefetch?: boolean;
  // We require "activeStyle" and do not support activeClassName because the css prop
  // has higher priority over activeClassName. The css prop is usefull to compose styles internally
  // to create different types of link components.
  // We can create className from the activeStyle if we want internally as "optimization".
  activeStyle: StyleProp;
}

interface BaseLinkProps extends LinkProps {
  // in our app to is only a string (not so in react-router)
  to: string;
  prefetch?: boolean;
  // can be used to force <a> plain tag even when to might starts w/o scheme (http/https)
  __forceExternal?: true;
  __fake?: true;
}

function useTextLinkCss() {
  return useGlobalStyles().textLink;
}

function useBlockCss() {
  return useGlobalStyles().blockLink;
}

function usePreloadUrl(props: BaseLinkProps | BaseNavLinkProps) {
  const { to } = props;
  const timers = useTimers<'preload'>();
  const { preloadUrl } = usePagesContext();
  if (!props.prefetch) {
    return {};
  }
  return {
    onMouseEnter: (e: AnyInternalOnly) => {
      props.onMouseEnter?.(e);
      timers.reset({ id: 'preload', timeoutMs: 400 }, () => {
        // we also use links for
        if (to.startsWith('/')) {
          preloadUrl({ pathname: to, search: '' });
        }
      });
    },
    // onClick: (e: AnyInternalOnly) => {
    //   props.onClick?.(e);
    //   timers.clearById('preload');
    //   if (to.startsWith('/')) {
    //     preloadUrl(to);
    //   }
    // },
    onMouseLeave: (e: AnyInternalOnly) => {
      props.onMouseLeave?.(e);
      timers.clearById('preload');
    },
  };
}

function BaseLink(props: BaseLinkProps) {
  const { to, __forceExternal, prefetch, __fake, color, ...pass } = props;

  const cssProp = useTextLinkCss();
  const preloadProps = usePreloadUrl(props);
  const finalCss = color ? [cssProp, { color }] : cssProp;

  if (__fake || !to) {
    return <span css={finalCss} {...pass} />;
  }
  // see FeatUrlInternalOrExternal
  // components in these modules works with external links too
  if (to.startsWith('http') || __forceExternal) {
    return (
      <a href={to} css={finalCss} {...pass}>
        {props.children}
      </a>
    );
  }

  return <LinkInternalImpl to={to} css={finalCss} {...pass} {...preloadProps} />;
}

//
// Basic links: text, block, ...
//

export type TextLinkProps = BaseLinkProps;

export function TextLink(props: TextLinkProps) {
  return <BaseLink {...props} />;
}

export function FakeTextLink(props: { children: React.ReactNode }) {
  return <BaseLink to="" {...props} />;
}

/**
 * Provides link outside of client-side (app page/views transitions) routing.
 *
 * Use it where you know it'll always be an external link.
 */
export function ExternalTextLink(props: BaseLinkProps) {
  return <TextLink {...props} __forceExternal />;
}

export function ExternalBlockLink(props: BlockLinkProps) {
  return <BlockLink {...props} __forceExternal />;
}

export function SystemPageTextLink(props: Omit<LinkProps, 'to'> & { page: SystemPageNames }) {
  const { page } = props;
  const systemUrl = useSystemUrlFn();
  const to = systemUrl(page);
  return <BaseLink {...props} to={to} />;
}

interface BlockLinkProps extends BaseLinkProps {
  label: string;
  extendedCss?: SerializedStyles | undefined;
}

/**
 * Creates link on block area.
 * - requires label (e.g. "see more" ala <a>see more</a>).
 */
export function BlockLink(props: BlockLinkProps) {
  const { label, extendedCss, ...pass } = props;
  const blockCss = useBlockCss();
  const finalCss = extendedCss ? [blockCss, extendedCss] : blockCss;
  return <BaseLink aria-label={label} css={finalCss} {...pass} />;
}

//
// Navigation links
//

function BaseNavLink(props: BaseNavLinkProps) {
  const { to, forceIsActive, prefetch, activeStyle, ...pass } = props;

  const globalStyles = useGlobalStyles();

  const isActive: AnyHowtoType = (match: MatchType<{}> | null) => {
    return match?.isExact || forceIsActive;
  };
  return (
    <NavLinkInternalImpl
      css={globalStyles.navTextLink}
      isActive={isActive}
      activeStyle={activeStyle}
      to={to}
      {...pass}
    />
  );
}

export function NavTextLink(props: BaseNavLinkProps) {
  const { activeStyle, to, ...passProps } = props;
  // see FeatUrlInternalOrExternal
  if (to.startsWith('http')) {
    return <ExternalTextLink to={to} {...passProps} />;
  }
  return <BaseNavLink to={to} activeStyle={activeStyle} {...passProps} />;
}

interface NavBlockLinkProps extends BaseNavLinkProps {
  label: string;
}

export function NavBlockLink(props: NavBlockLinkProps) {
  const { label, ...pass } = props;
  const globalStyles = useGlobalStyles();
  const preloadProps = usePreloadUrl(props);
  // see FeatUrlInternalOrExternal
  if (props.to.startsWith('http')) {
    return <ExternalBlockLink {...props} />;
  }
  return (
    <BaseNavLink aria-label={label} css={globalStyles.navBlockLink} {...pass} {...preloadProps} />
  );
}

//
// Download links
//

export function DownloadBlockLink(
  props: HtmlTagComponentProps<'a'> & { label: string; href: string }
) {
  const { label, ...pass } = props;
  const blockCss = useBlockCss();
  return (
    <a aria-label={label} download css={blockCss} {...pass}>
      {props.children}
    </a>
  );
}

/**
 * Props:
 *
 * -- customize:
 *   -- inNewTab: whether to open in new tab (defaults to false )
 */
export function DownloadTextLink(props: HtmlTagComponentProps<'a'> & { href: string }) {
  const { href, ...pass } = props;
  return (
    <BaseLink __forceExternal to={href} download {...pass}>
      {props.children}
    </BaseLink>
  );
}

//
// Button links
//

export function OutlineButtonLink(
  props: {
    to: string;
  } & React.ComponentProps<typeof OutlineButton>
) {
  const { to, ...pass } = props;
  return (
    <BlockLink label={''} to={to}>
      <OutlineButton {...pass} />
    </BlockLink>
  );
}
