import React, { useState } from 'react';
import { useTimers } from '@whop/react/timers';
import { useDidMount } from '@whop/react/component';

import { Trans } from '@lingui/react';
import { protoUI } from '../utils.styles/prototype';
import { DotAnimation } from './DotAnimation';
import { LoaderInlineProps } from '../types.contracts/loader.inline';
import { useColors } from '../app.use/useColors';

/**
 * @example
 * empty – show nothing
 * short – show dots
 * long – show dots + message
 */
type ProgressiveLoadState = 'empty' | 'short' | 'long';

// @todo: it should delay independently from when the previous is called
const defaultDelays = {
  short: 250,
  long: 1000,
};

/**
 *
 */
function useProgressiveLoader(props: LoaderInlineProps): ProgressiveLoadState {
  const {
    initialState,
    forceState,
    delayShort = defaultDelays.short,
    delayLong = defaultDelays.long,
  } = props;

  const [loadState, setLoadState] = useState<ProgressiveLoadState>(
    forceState ?? (initialState || 'empty')
  );
  const timers = useTimers<ProgressiveLoadState>();

  useDidMount(() => {
    // if state is forced, do not ever update it
    if (forceState) {
      return;
    }
    timers.set(
      {
        id: 'short',
        timeoutMs: delayShort,
      },
      () => {
        if (loadState === 'empty') {
          setLoadState('short');
        }
        timers.set(
          {
            id: 'long',
            timeoutMs: delayLong,
          },
          () => {
            if (loadState === 'short') {
              setLoadState('long');
            }
          }
        );
      }
    );
  });
  return loadState;
}

export function InlineDotLoader(props: LoaderInlineProps) {
  const colors = useColors();
  const progressiveState = useProgressiveLoader(props);

  const color = colors.primary;
  if (progressiveState === 'short') {
    return <DotAnimation color={color} />;
  }
  if (progressiveState === 'long') {
    return (
      <protoUI.FlexRow css={{ color }}>
        {props.label ?? <Trans>Loading</Trans>} <DotAnimation color={color} />
      </protoUI.FlexRow>
    );
  }
  return <protoUI.Block height={16} />;
}
