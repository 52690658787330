import * as React from 'react';
import { createContext, useContext } from 'react';

import { SsrMatchMediaFn } from '../app.types/state';

type UniversalCode = {
  ssrMatchMedia?: SsrMatchMediaFn;
};

const UniversalCodeContext = createContext<UniversalCode>({});

export function UniversalCodeProvider(props: { children: React.ReactNode; value: UniversalCode }) {
  return (
    <UniversalCodeContext.Provider value={props.value}>
      {props.children}
    </UniversalCodeContext.Provider>
  );
}

/**
 * Provides implementation of "interfaces" which might be different between client/server.
 *
 * Term universal comes from react-router authors, couldn't find that medium article,
 * see at least https://www.freecodecamp.org/news/demystifying-reacts-server-side-render-de335d408fe4/
 */
export function useUniversalCode(): UniversalCode {
  return useContext(UniversalCodeContext);
}
