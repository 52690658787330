import { HttpResource, HttpResourceCallable } from '@whop/containers';
import { AppResourceContext } from '../app.types/state';
import { allValuesHandled } from '@whop/utils/enums';

type PossiblyAsyncCb = () => AnyInternalOnly | Promise<AnyInternalOnly>;

type DefinedTask<TResult> = {
  resolve: () => Promise<TResult>;
  getTaskId: () => string;

  // @review: this is bad API, improve somehow
  __runBefore?: PossiblyAsyncCb;
  __runAfter?: PossiblyAsyncCb;
};

class AsyncTaskError extends Error {}

// @todo add canceled
function createAsyncTask<T>(taskDefinition: DefinedTask<T>) {
  let _state: 'idle' | 'rejected' | 'fulfilled' | 'resolved' | 'pending' | 'canceled' = 'idle';

  return {
    getTaskId: taskDefinition.getTaskId,
    run: async () => {
      _state = 'pending';
      // let result;

      const { __runBefore } = taskDefinition;
      __runBefore && (await __runBefore());

      try {
        await taskDefinition.resolve();
      } catch (e) {
        _state = 'rejected';
        console.error(e);
        const { __runAfter } = taskDefinition;
        __runAfter && (await __runAfter());
        return { ok: false };
      }

      const { __runAfter } = taskDefinition;
      __runAfter && (await __runAfter());
      return { ok: true };
    },
    did: (what: 'reject' | 'fulfill' | 'resolve') => {
      switch (what) {
        case 'reject':
          return _state === 'rejected';
        case 'fulfill':
          return _state === 'fulfilled';
        case 'resolve':
          return _state === 'resolved';
        default:
          return allValuesHandled(what, false);
      }
    },
    cancel: () => {
      console.warn('warning: cancel not implemented yet');
    },
    selectValue: () => {},
  };
}

export interface AnyAsyncTask {
  run: () => Promise<{ ok: boolean }>;
}

/**
 * @todo consolidate with AnyAsyncTask
 */
export interface AsyncTask {
  run: () => Promise<{ ok: boolean }>;
  getTaskId: () => string;
  // Note: retry to explicitly retry?
  // retry: () => Promise<{ value: Result }>;
  // did: (what: 'reject' | 'fulfill' | 'resolve') => boolean;
  // cancel: () => void;
}

export type CustomTaskCreator<TInput, TTask extends AnyAsyncTask> = (data: TInput) => TTask;

export type CustomParamTaskCreator<
  TParams extends Array<AnyGenerics>,
  TInput,
  TTask extends AnyAsyncTask
> = (...params: TParams) => (data: TInput) => TTask;

export type AsyncTaskCreator<TData> = (data: TData) => AsyncTask;
export type AsyncParamTaskCreator<TParams extends Array<AnyGenerics>, TData = void> = (
  ...params: TParams
) => AsyncTaskCreator<TData>;

type DefinePlainTaskOptions<TData, TPayload> = {
  resourceContext: AppResourceContext;
  __runAfter?: PossiblyAsyncCb;
  __runBefore?: PossiblyAsyncCb;
  mapToPayload?: (data: TData) => TPayload;
  taskId: string;
};

export function definePlainTaskFromResource<TData extends {} = {}, TPayload extends {} = TData>(
  resource: HttpResource,
  options: DefinePlainTaskOptions<TData, TPayload>
): AsyncTaskCreator<TData> {
  const { __fetchJson } = options.resourceContext;
  const { mapToPayload } = options;
  return (data: TData) =>
    createAsyncTask({
      __runAfter: options.__runAfter,
      __runBefore: options.__runBefore,
      getTaskId: () => options.taskId,
      resolve: async () => {
        const result = await __fetchJson(resource, mapToPayload ? mapToPayload(data) : data);
        if (result.status === 'ok') {
          return result.data;
        }
        throw new AsyncTaskError();
      },
    });
}

type DefineParamTaskOptions<TData, TPayload, TParams extends Array<AnyGenerics>> = Omit<
  DefinePlainTaskOptions<TData, TPayload>,
  'taskId'
> & {
  getTaskId: (...params: TParams) => string;
};

export function defineParamTaskFromResource<
  TParams extends Array<AnyGenerics>,
  TData,
  TPayload = TData
>(
  paramResource: HttpResourceCallable,
  options: DefineParamTaskOptions<TData, TPayload, TParams>
): AsyncParamTaskCreator<TParams, TData> {
  return (...params: TParams) => {
    const resource = paramResource(...params);
    return definePlainTaskFromResource(resource, {
      ...options,
      taskId: options.getTaskId(...params),
    });
  };
}
