import { CacheType } from '@whop/app/types';
import { FlatCache } from '../app.types/app';

// @review: consolidate with createFlatResourceCache?

/**
 * Creates a "flat" cache from normal one.
 */
export function createFlatCache<TValue, TDefaultValue = TValue>(
  cache: CacheType<TValue>,
  key: string
): FlatCache<TValue, TDefaultValue> {
  return {
    setValue: (value: TValue) => {
      cache.setValue(key, value);
    },
    getValue: (defaultValue: TDefaultValue) => {
      return cache.getValue(key, defaultValue);
    },
    exists: () => {
      return cache.hasKey(key);
    },
    delete: () => {
      return cache.deleteKey(key);
    },
  };
}
