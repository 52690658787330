import React from 'react';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { css } from '@emotion/core';
import { IconButton } from '../tsx.design/IconButton';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ExpandButton(props: {
  label: string;
  expanded: boolean;
  onClick?: () => void;
  size?: 'small' | 'medium';
  style?: { [key: string]: string };
  color?: SvgIconProps['color'];
}) {
  const { style = {}, color = 'primary' } = props;

  return (
    <IconButton label={props.label} onClick={props.onClick} size={props.size} style={style}>
      <ExpandIcon
        className={props.expanded ? 'expanded' : ''}
        css={css`
          transition: transform 200ms;
          &.expanded {
            transform: rotate(180deg);
          }
        `}
        fontSize="large"
        color={color}
      />
    </IconButton>
  );
}
