import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { Trans } from '@lingui/react';
import { setupEvent, bulkRemoveEvents } from '@whop/browser/events';
import { StyleProp } from '@whop/react/types';

//
// TMP Utils:
//

// @review: can it work for 100% width etc?
function SvgPlaceholderImg(props: {
  maxWidth: string;
  width: number | string;
  height: number | string;
}) {
  const { width, height, maxWidth } = props;

  // {width}×{height}

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={maxWidth ? width : undefined}
      css={css`
        background-color: #f1f1f1;
        max-width: ${maxWidth};
        width: ${maxWidth ? '' : width};
      `}
    >
      <text
        x="50%"
        y="50%"
        fill="#555"
        alignmentBaseline="middle"
        fontFamily="monospace, sans-serif"
        fontSize="18"
        textAnchor="middle"
      >
        <Trans>No image</Trans>
      </text>
    </svg>
  );
}

function useImgState(url: string) {
  const [state, setState] = useState<'initial' | 'loaded' | 'error'>('initial');
  useEffect(() => {
    if (!url) {
      // Note: if url is empty its already in error state
      setState('error');
      return;
    }

    const onLoad = () => {
      setState('loaded');
    };

    const onError = () => {
      setState('error');
    };

    let img = new Image();

    const removeEvents = bulkRemoveEvents(
      setupEvent(img, 'load', onLoad),
      setupEvent(img, 'error', onError)
    );

    img.src = url;
    return removeEvents;
  }, [url]);

  return state;
}

//
// TMP Utils.
//

interface BasicImageProps {
  src: string;
  // Note: height is required
  height: number;
  maxWidth?: number | string;
  objectFit?: StyleProp['objectFit'];
  objectPosition?: StyleProp['objectPosition'];
  alt?: string;
  customPlaceholder?: JSX.Element;
}

function withPxOrNull(val?: number | string): string | null {
  if (typeof val === 'string') {
    return val;
  }
  return val === null || val === undefined ? null : `${val}px`;
}

/**
 * @todo maxWidth doesn't work w/ placeholder
 */

export function BasicImage(props: BasicImageProps) {
  const {
    src,
    maxWidth,
    height,
    objectFit = 'contain',
    objectPosition,
    customPlaceholder,
    ...pass
  } = props;

  const imgState = useImgState(src);

  const maxWidthWithUnit = withPxOrNull(maxWidth) ?? '100%';

  if (imgState === 'error') {
    return (
      props.customPlaceholder ?? (
        <SvgPlaceholderImg maxWidth={maxWidthWithUnit} height={height} width={'100%'} />
      )
    );
  }

  return (
    <img
      css={css`
        width: 100%;
        max-width: ${maxWidthWithUnit};
        height: ${height}px;
        vertical-align: middle;
        object-fit: ${objectFit};
        object-position: ${objectPosition};
      `}
      alt=""
      src={src}
      height={height}
      {...pass}
    />
  );
}
