import { ParamResourceCache, RuntimeResourceCache } from './types';

import { definePlainResource, PlainResourceProps } from './plain';
import { createFlatResourceCache } from './utils';
// import { stringifyToStableJson } from '@whop/utils/json';

export function defineParamResource<
  Resource,
  Params extends Array<AnyGenerics>,
  DefaultResource = Resource
>(props: {
  name?: string;
  instancesCache: RuntimeResourceCache;
  getKey: (...params: Params) => string;
  resolve: (...params: Params) => Promise<Resource>;
  defaultValue: DefaultResource;
  cache: ParamResourceCache<Resource, null>;
  readFromCache?: PlainResourceProps<Resource>['readFromCache'];
  options: PlainResourceProps<Resource>['options'];
}) {
  const { name = 'parametrized', instancesCache, options } = props;
  return (...p: Params) => {
    // const key = stringifyToStableJson(p);
    const key = props.getKey(...p);
    const existing = instancesCache.getValue(key, null);
    if (existing) {
      return existing;
    }
    const plainCache = createFlatResourceCache(props.cache.getOrCreateCache(name), key);
    const asPlain = definePlainResource({
      name: `${name}:${key}`,
      cache: plainCache,
      readFromCache: props.readFromCache,
      fallbackValue: props.defaultValue,
      resolve: () => props.resolve(...p),
      options,
    });
    instancesCache.setValue(key, asPlain);
    return asPlain;
  };
}
