import React from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import { PopupCard } from '../tsx.design/PopupCard';
import { css } from '@emotion/core';
import { POPUP_Z_INDEX } from '../app.constants/dom';

export type PopperProps = React.ComponentProps<typeof Popper>;

/**
 *
 */
export function BasicPopper(props: {
  id: string;
  children: React.ReactNode;
  anchorEl: Element | null;
  open: boolean;
  onClose: () => void;
  className?: string;
  // whitelisted props of material-ui Popper >>
  placement?: PopperProps['placement'];
  // << whitelisted props of material-ui Popper
}) {
  if (!props.anchorEl) {
    // Note: when anchor is not ready, we don't render the popper because it wouldn't
    // know where to attach to. Not 100% sure its correct, but it helps.
    return null;
  }
  return (
    <Popper
      id={props.id}
      placement={props.placement}
      open={props.open && !!props.anchorEl}
      anchorEl={props.anchorEl}
      css={css`
        z-index: ${POPUP_Z_INDEX};
      `}
      className={props.className}
      transition
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={props.onClose}>
          <Fade {...TransitionProps} timeout={50}>
            <PopupCard>{props.children}</PopupCard>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
}
