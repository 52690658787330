import React from 'react';
import { css } from '@emotion/core';
import { Box, BoxProps } from '@whop/system/box';
import { useSystemValueFn } from '@whop/system/context';
import { useResolveResponsiveFn } from '../app.base/responsive';

type PrototypeProps = BoxProps;

function FlexColumn(props: PrototypeProps) {
  return (
    <Box
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
      `}
      {...props}
    />
  );
}

function FlexRow(props: PrototypeProps) {
  return (
    <Box
      css={css`
        display: flex;
        align-items: center;
      `}
      {...props}
    />
  );
}

function Block(props: PrototypeProps) {
  return <Box {...props} />;
}

// Exported to force using it namespace protoUI.FlexColumn:
// - typescript imports always work
// - only one import
// - easy to change to other "prototype" component
// - its clear its only prototype code so developer won't hesitate to change it

function SpacedBlock(props: PrototypeProps) {
  const systemValue = useSystemValueFn();
  const padding = systemValue('space', 1);
  return <Box {...props} padding={padding} />;
}

/**
 * Splits two blocks.
 * - adds space between
 * - switches to column layout on small breakpoint
 */
function SplitTwo(props: { children: [React.ReactNode, React.ReactNode] }) {
  const systemValue = useSystemValueFn();
  const resolveResponsive = useResolveResponsiveFn();
  const space = systemValue('space', 1);
  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${resolveResponsive(['column', 'row'])};
      `}
    >
      {props.children[0]}
      <div
        css={css`
          width: ${space}px;
          height: ${space}px;
        `}
      />
      {props.children[1]}
    </div>
  );
}

function Absolute(props: {
  children: React.ReactNode;
  right?: number;
  top?: number;
  left?: number;
  bottom?: number;
}) {
  const { top, right, bottom, left } = props;
  return <div css={{ position: 'absolute', top, right, bottom, left }} {...props} />;
}

function Relative(props: { children: React.ReactNode }) {
  return (
    <div
      css={css`
        position: relative;
      `}
      {...props}
    />
  );
}

/**
 * AntiFlexBlock is just semantic alias to div to make JSX obvious.
 *
 * It should be used in flex layout to wrap children, which you don't know what it will be.
 *
 * For example if the children is combination of text nodes + inline tags (span, strong), the inline
 * tags height will be broken.
 */
function AntiFlexBlock(props: { children: React.ReactNode }) {
  return <div css={{ width: '100%' }} {...props} />;
}

/**
 * @deprecated use presetUI instead
 */
export const protoUI = {
  FlexColumn,
  FlexRow,
  Block,
  SpacedBlock,
  SplitTwo,
  Relative,
  Absolute,
  AntiFlexBlock,
};
