import React from 'react';
import { css } from '@emotion/core';
import { SystemBox } from '@whop/system';

import { systemUI } from '../utils.styles/system';

import { useMenuContainer } from '../app.use/useMenuContainer';
import { useResource } from '../pkg.resources/useResource';
import { useTheme } from '@whop/system/context';
import { useMediaIsDown } from '../app.base/responsive';
import { useLocation } from 'react-router';
import { VerticalPopupMenu } from '../tsx.menu/VerticalPopupMenu';
import { SidemenuLayout } from '../pkg.theme/types';

export const DEFAULT_MENU_WIDTH = '400px';

// TODO: implement passing menu component
// & { MenuComponent: typeof React.Component }
export function SideMenuLayout(props: { children: React.ReactNode }) {
  const theme = useTheme();
  const themeLayout = theme.layout as SidemenuLayout;
  const location = useLocation();

  const menuContainer = useMenuContainer();
  const headerItems = useResource(menuContainer.listHeader);
  const menuItems = useResource(menuContainer.listMenu);

  const getInitiallyExpanded = () => {
    const initiallyExpanded = {};
    menuItems.forEach((item) => {
      if (item.url === location.pathname) {
        initiallyExpanded[item.id] = true;
      } else {
        item.children.forEach((child) => {
          if (child.url === location.pathname) {
            initiallyExpanded[item.id] = true;
          }
        });
      }
    });
    return initiallyExpanded;
  };
  const [expanded, setExpanded] = React.useState(getInitiallyExpanded());

  const isLgDown = useMediaIsDown('lg');

  return (
    <systemUI.Box
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: theme.space[1],
      }}
    >
      {isLgDown ? null : (
        <>
          <systemUI.Box
            style={{
              minWidth: themeLayout.menuWidth || DEFAULT_MENU_WIDTH,
              maxWidth: themeLayout.menuWidth || DEFAULT_MENU_WIDTH,
              marginTop: theme.space[1],
              alignSelf: 'stretch',
              backgroundColor: theme.colors.primary,
            }}
          >
            <VerticalPopupMenu
              headerItems={headerItems}
              menuItems={menuItems}
              markActive={true}
              expandedItems={expanded}
              onRedirect={() => setExpanded(getInitiallyExpanded())}
            />
          </systemUI.Box>
        </>
      )}

      <systemUI.Box style={{ width: isLgDown ? '100%' : `calc(100% - ${themeLayout.menuWidth})` }}>
        <SystemBox
          system={{ padding: [1, 2, 3], paddingTop: [0] }}
          css={css`
            box-sizing: border-box;

            width: 100%;
          `}
        >
          {props.children}
        </SystemBox>
      </systemUI.Box>
    </systemUI.Box>
  );
}
