import { AppResourceContext } from '../app.types/state';

type AppResourceCtxInternal = Omit<AppResourceContext, 'id'>;

export function createAppResourceContext(options: AppResourceCtxInternal): AppResourceContext {
  return {
    ...options,
    id: 'opaque-type',
  };
}

export function readAppResCtx(ctx: AppResourceContext): AppResourceCtxInternal {
  return ctx;
}
