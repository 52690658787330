import { allValuesHandled } from '@whop/utils/enums';
import {
  ResourceStates,
  PlainResource,
  FlatResourceCache,
  ResourceInternalStates,
  PromiseManager,
  SubscribeCallbackFn,
  ResourceShape,
  RefetchInternalOptions,
} from './types';
import { ResourceFetchError } from './errors';

function notNil<T>(value: T | null | undefined): boolean {
  return value !== null && value !== undefined;
}

function readFromCacheIdentity<T>(serialized: unknown): T {
  return serialized as T;
}

export type PlainResourceProps<Resource, DefaultResource = Resource> = {
  name: string;
  resolve: () => Promise<Resource>;
  fallbackValue: DefaultResource;
  cache: FlatResourceCache<Resource, null>;
  initialValue?: Resource;
  readFromCache?: (serialized: AnyHowtoType) => Resource; // @todo make serialized Serialized<Resource>
  onChange?: () => void;
  dependencies?: ResourceShape[];
  options: {
    __manageResolve: PromiseManager;
    __doNotWarnSuspense?: boolean;
    __isDebug: boolean;
  };
};

/**
 * @overview
 * Todo:
 * - error handling
 */

export function definePlainResource<Resource, DefaultResource = Resource>(
  props: PlainResourceProps<Resource, DefaultResource>
): PlainResource<Resource, DefaultResource> {
  const {
    fallbackValue,
    initialValue,
    cache,
    readFromCache = readFromCacheIdentity,
    resolve,
    options: { __manageResolve, __doNotWarnSuspense, __isDebug },
    onChange,
    name,
    dependencies = [],
  } = props;

  let state: ResourceInternalStates = cache.exists() || notNil(initialValue) ? 'cached' : 'idle';
  let _subscriptions: SubscribeCallbackFn<Resource | DefaultResource>[] = [];
  let _dependents: Set<ResourceShape> = new Set();

  function getResourceOrNull(): Resource | null {
    // @todo: force Resource to extend from an object, to avoid '' or 0 values problems
    const serialized = cache.getValue(null);
    if (serialized) {
      // @todo: make the cache return Serialized<Resource>
      return readFromCache((serialized as unknown) as object);
    }
    return initialValue ?? null;
  }

  function selectResource() {
    return getResourceOrNull() ?? fallbackValue;
  }

  function isInState(value: ResourceStates) {
    switch (value) {
      case 'loaded':
        // From user point of view its loaded even if cached.
        return state === 'loaded' || state === 'cached';
      case 'loading':
        return state === 'loading';
      case 'error':
        return state === 'error';
      case 'idle':
        return state === 'idle';
      default:
        return allValuesHandled(value, false);
    }
  }

  async function fetch(fetchOptions?: { __reload?: boolean } & RefetchInternalOptions) {
    const isReload = !!fetchOptions?.__reload;
    // @review if its in "loading" state and the resource is concurrently
    // requested, it should return same Promise for both calls.
    const hasAlreadyLoaded = isInState('loaded') || isInState('error');
    if (hasAlreadyLoaded && !isReload) {
      return selectResource();
    }
    state = 'loading';
    // step: if resource has dependencies we have to reload them first so that
    // this resource's fetch is resolved with up-to-date dependent data
    if (dependencies.length && fetchOptions?.__skipDependencies !== true) {
      await Promise.all(dependencies.map((dep) => dep.reload({ __skipDependents: true })));
    }
    let result: Resource | DefaultResource = fallbackValue;
    try {
      result = await __manageResolve.manageResolve(resolve, {
        key: name,
        canReusePromises: !isReload,
      });
    } catch (e) {
      state = 'error';
      if (e instanceof ResourceFetchError) {
        // note: for debug log whole error with stack trace, on production only the message
        __isDebug ? console.error(e) : console.error(e.message);
      } else {
        // note: unknown error => always log whole
        console.error(e);
      }
      // step: return immediately without notifying the error change
      return fallbackValue;
    }
    state = 'loaded';
    cache.setValue(result);

    onChange && onChange();
    _subscriptions.forEach((fn) => fn(result));

    // step: once we have up-to-date data for this resource we should update all resources where
    // this one is registered as "dependency" (unless the re-fetch was triggered by the dependency itself)
    if (_dependents.size && fetchOptions?.__skipDependents !== true) {
      await Promise.all(
        Array.from(_dependents.values()).map((dep) => dep.reload({ __skipDependencies: true }))
      );
    }

    return result;
  }

  const select = () => {
    return selectResource();
  };

  const refetch = async (refetchOptions?: RefetchInternalOptions) => {
    state = 'idle';
    cache.delete();
    return await fetch({ __reload: true, ...refetchOptions });
  };

  const subscribe = (cbFn: SubscribeCallbackFn<Resource | DefaultResource>) => {
    _subscriptions.push(cbFn);
    const unsubscribe = () => {
      _subscriptions = _subscriptions.filter((fn) => fn !== cbFn);
    };
    return unsubscribe;
  };

  const reload = async (refetchOptions?: RefetchInternalOptions) => {
    await fetch({ __reload: true, ...refetchOptions });
  };

  const load = async () => {
    await fetch();
  };

  const clear = () => {
    cache.delete();
  };

  const resource: PlainResource<Resource, DefaultResource> = {
    fetch,
    getOrFetch: fetch,
    refetch,
    load,
    reload,
    select,
    read: (readOptions) => {
      if (isInState('error')) {
        return fallbackValue;
      }
      const res = getResourceOrNull();
      if (res === null) {
        // Note: do not warn if explicitly disabled or we are using suspense on client on actual
        // production site (we only warn on development to prevent SSR issues).
        // Use `readOptions.__doNotWarnSuspense` via resources-enabled hook API to prevent warning during
        // page transitions on development time.
        const doNotWarn = Boolean(readOptions?.__doNotWarnSuspense) || __doNotWarnSuspense;
        if (!doNotWarn) {
          console.error(
            `error(resources): Reading non-preloaded resource "${name}". This is noop for SSR.`
          );
        }
        throw fetch();
        // Note: change to throw when suspense is ready... right know it makes a bad experience
        // with HMR.
        // return select();
      }
      return res;
    },
    is: isInState,
    subscribe,
    clear,
    registerDependent: (resource: ResourceShape) => {
      _dependents.add(resource);
    },
    unregisterDependent: (resource: ResourceShape) => {
      _dependents.delete(resource);
    },
    getName: () => {
      return name;
    },
  };

  for (const dep of dependencies) {
    dep.registerDependent(resource);
  }

  return resource;
}
