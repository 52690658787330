import React from 'react';
import { LOGIN_SYNC_LS_KEY } from '../app.constants/persist';
import { useLoginContainer } from '../app.use/useLoginContainer';

export function AppBehavior(props: { children: React.ReactElement }) {
  const loginContainer = useLoginContainer();

  React.useEffect(() => {
    window.addEventListener('storage', (e) => {
      if (e.key === LOGIN_SYNC_LS_KEY) {
        if (e.newValue) {
          loginContainer.forceLogin();
        } else {
          loginContainer.forceLogout();
        }
      }
    });
  }, [loginContainer]);

  return props.children;
}
