import * as models from '@whop/core/types';
import * as api from '@whop/backend/types';
import {
  coerceStr,
  coerceDate,
  coerceDateOrToday,
  coerceDateOrFallback,
  coerceInt,
  coerceNum,
} from '@whop/utils/values';

export function coerceImage(payload: api.NullishField<api.ImageJsonType>): models.ImageModel {
  // NotePrototype: unclear shape
  return {
    src: payload?.full_size || '',
    sizes: {
      fullSize: payload?.full_size || '',
      big: payload?.big || '',
      medium: payload?.medium || '',
      small: payload?.small || '',
    },
  };
}

export function coerceAttachment(payload: api.AttachmentJsonType): models.AttachmentModel {
  return {
    downloadUrl: payload.url_path,
    title: payload.label,
    size: payload.file_size,
    mediaType: payload.media_type,
  };
}

export function coerceHtml(field: api.HtmlContent): models.HtmlOpaqueType {
  return field;
}

// coerce primitives and app-agnostic types
export const coerce = {
  str: coerceStr,
  date: coerceDate,
  dateOrToday: coerceDateOrToday,
  dateOrFallback: coerceDateOrFallback,
  int: coerceInt,
  num: coerceNum,
};

// coerce app-wise types
export const coerceModel = {
  img: coerceImage,
  html: coerceHtml,
  attachment: coerceAttachment,
};
