import { createPromiseManager } from '../app.client/promiseManager';

/**
 * @overview There can be created instances for BROWSER only. The instances will not be re-created
 * during development, so HMR won't results in forgetting the instances state.
 *
 * This works because e.g. setupClient depends on everything in the app (although maybe lazily), so its reloaded.
 * However changes in another files doesn't change this file.
 */

export const manageResolve = createPromiseManager();
