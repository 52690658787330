import { IS_PRODUCTION_BUILD } from '../app.constants/env';
import { ProjectIdNames, ThemeIdNames } from '@whop/core/types';

/**
 * If you change one of the constants HMR will update the app.
 */

const PRODUCTION_NOT_OVERRIDEN = '';

export const OVERRIDE_PROJECT_ID: ProjectIdNames | '' = IS_PRODUCTION_BUILD
  ? PRODUCTION_NOT_OVERRIDEN
  : '';

export const OVERRIDE_THEME_ID: ThemeIdNames | '' = IS_PRODUCTION_BUILD
  ? PRODUCTION_NOT_OVERRIDEN
  : '';
