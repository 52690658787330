import { addEventListener } from 'consolidated-events';

type ListenerFn = Function;
type RemoveListenerFn = Function;
type EventNamesEnum = 'scroll' | 'load' | 'error';

/**
 * Like target.addEventListener, but more safe (using consolidated-events package).
 *
 * It has different API therefore different name:
 *
 * - returns function to remove the listener instead of fragile API removeEventListener
 * - accepts the target (to avoid monkey patching)
 *
 * Usage:
 * - passive option for e.g. scoll events
 */
export function setupEvent(
  target: EventTarget,
  eventName: EventNamesEnum,
  listener: ListenerFn,
  options?: object
): RemoveListenerFn {
  return addEventListener(target, eventName, listener, options);
}

/**
 * Fallbacks to passive listening (https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners)
 */
export function addScrollListener(listener: ListenerFn, options?: { passive?: boolean }) {
  return setupEvent(window, 'scroll', listener, { passive: options?.passive ?? true });
}

/**
 * Just a semantic/typed helper to combine multiple "remove event callback" into one function
 */
export function bulkRemoveEvents(...args: RemoveListenerFn[]) {
  return () => {
    for (const remove of args) {
      remove();
    }
  };
}
