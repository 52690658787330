// @todo: move to "app plugins" package
// @review: is ctx worth to type?
type CreatePluginContextOptions<TAppPluginsCtx> = { ctx: TAppPluginsCtx };
type GtmPluginData = { gtmId: string };

function updateHTML(pluginData: GtmPluginData) {
  const { gtmId } = pluginData;
  return (initialHtml: string) => {
    if (!gtmId) {
      return initialHtml;
    }
    const scriptHtml = `<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmId}');</script>`;
    const noscriptHtml = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;

    let currentHtml = initialHtml;
    const headInjectAt = currentHtml.indexOf('<head>') + '<head>'.length;
    currentHtml = `${currentHtml.slice(0, headInjectAt)}${scriptHtml}${currentHtml.slice(
      headInjectAt
    )}`;
    const bodyInjectAt = currentHtml.indexOf('<body>') + '<body>'.length;
    currentHtml = `${currentHtml.slice(0, bodyInjectAt)}${noscriptHtml}${currentHtml.slice(
      bodyInjectAt
    )}`;
    return currentHtml;
  };
}

export function setup<TAppPluginsCtx>(options: {
  // note: this is non-standard (just for this plugin) setup option to decouple app from plugin
  resolvePluginContext: (ctx: TAppPluginsCtx) => GtmPluginData;
}) {
  async function createPluginContext({ ctx }: CreatePluginContextOptions<TAppPluginsCtx>) {
    return options.resolvePluginContext(ctx);
  }
  return {
    updateHTML,
    createServerContext: createPluginContext,
    createClientContext: createPluginContext,
  };
}
