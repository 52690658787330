import { css } from '@emotion/core';
import React from 'react';

import { html } from '../utils.styles/html';
import { useColors } from '../app.use/useColors';
import { Trans } from '@lingui/react';

interface ContentEmptyProps {
  children?: React.ReactNode;
}

// @review: deprecate?
export function ContentEmpty(props: ContentEmptyProps) {
  const { children, ...pass } = props;
  const colors = useColors();
  const color = colors.hairline;
  return (
    <html.p
      css={css`
        color: ${color};
      `}
      {...pass}
    >
      {props.children || <Trans>No content</Trans>}
    </html.p>
  );
}
