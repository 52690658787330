import React, { useContext } from 'react';
import { SystemBox } from '@whop/system';
import { HeadingLevelEnum } from '../app.types/styles';
import { SystemBoxAs, SystemBoxProps } from '@whop/system/types';

const HeadingLevel = React.createContext<HeadingLevelEnum>(1);

function incrementLevel(from: HeadingLevelEnum): HeadingLevelEnum {
  if (from <= 5) {
    return (from + 1) as HeadingLevelEnum;
  }
  return 6;
}

/**
 * Provides hierarchy structure (level 1-6) for Heading component.
 */
export function HeadingSection<T extends SystemBoxAs>(props: SystemBoxProps<T>) {
  const level = useContext(HeadingLevel);
  return (
    <HeadingLevel.Provider value={incrementLevel(level)}>
      <SystemBox as="section" {...props} />
    </HeadingLevel.Provider>
  );
}

export function ExplicitHeadingSection<T extends SystemBoxAs>(
  props: SystemBoxProps<T> & { level: number }
) {
  const { level, ...pass } = props;
  return (
    <HeadingLevel.Provider value={props.level}>
      <SystemBox as="section" {...pass} />
    </HeadingLevel.Provider>
  );
}

export function useHeadingLevel(): HeadingLevelEnum {
  return useContext(HeadingLevel);
}
