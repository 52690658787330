// import * as React from 'react';
import { createContext, useContext, useCallback } from 'react';

import { SystemThemeSpec } from './types';

// @ts-ignore
const SystemContext = createContext<{
  theme: SystemThemeSpec;
  useResponsiveIdx: () => number;
} | null>(null);

export const SystemProvider = SystemContext.Provider;

export function useTheme(): SystemThemeSpec {
  const value = useContext(SystemContext);
  if (!value) {
    throw new Error('Please use SystemProvider.');
  }
  // @ts-ignore
  return value.theme;
}

export function useResponsiveIdx(): number {
  const value = useContext(SystemContext);
  if (!value) {
    throw new Error('Please use SystemProvider.');
  }
  // @ts-ignore
  return value.useResponsiveIdx();
}

/**
 * @example usage:
const systemValue = useSystemValueFn();
const padding = systemValue('space', 1);
 * @returns A function that returns design-system value from theme
 */
export function useSystemValueFn() {
  const theme = useTheme();

  return useCallback(
    (name: 'space', key: keyof SystemThemeSpec['space']) => {
      return theme[name][key];
    },
    [theme]
  );
}
