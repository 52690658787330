import React from 'react';
import { createContext, useContext } from 'react';
import { AsyncRegistryManager, AsyncRegistryShape } from './types';

const AsyncRegistryContext = createContext<AsyncRegistryManager | null>(null);

export function useInternalContext(): AsyncRegistryShape {
  const value = useContext(AsyncRegistryContext);
  if (!value) {
    throw new Error('Please use AsyncRegistryProvider.');
  }
  return value.registry;
}

export function AsyncRegistryProvider(props: {
  children: React.ReactNode;
  fallback: React.ComponentType<AnyGenerics>;
  value: AsyncRegistryManager;
}) {
  // <React.Suspense fallback={props.fallback}>
  // </React.Suspense>
  return (
    <AsyncRegistryContext.Provider value={props.value}>
      {props.children}
    </AsyncRegistryContext.Provider>
  );
}
