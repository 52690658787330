import { defineHttpResources } from '@whop/containers';
import { ListProductVariant } from './types';

export { mapProductItem as mapProduct } from './mapping';

export const httpResources = defineHttpResources({
  promoProducts: { url: `/api/product/variant/promos/` },
  productDetail: (id: string) => ({
    url: `/api/product/variant/${id}/`,
  }),
  multiProductDetails: (ids: string[]) => ({
    url: `/api/product/variant/?ids=${ids.join(',')}`,
  }),
  listProductsByIds: (ids: string[]) => ({
    url: `/api/product/variant/?ids=${ids.join(',')}`,
  }),
  listFromVariant: (id: string, listName: ListProductVariant) => ({
    url: `/api/product/variant/${id}/list/${listName}/`,
  }),
  similarProducts: (id: string) => ({
    url: `/api/product/variant/${id}/list/similar/`,
  }),
  relatedProducts: (id: string) => ({
    url: `/api/product/variant/${id}/list/related/`,
  }),
  listProductsInCategory: (id: string) => ({
    url: `/api/search/filter/${id}/`,
  }),
  favoriteProduct: (id: string) => ({
    url: `/api/product/variant/${id}/favorite/`,
    method: 'POST',
  }),
  unfavoriteProduct: (id: string) => ({
    url: `/api/product/variant/${id}/favorite/`,
    method: 'DELETE',
  }),
  unfavoriteAll: {
    url: '/api/product/variant/favorites/',
    method: 'DELETE',
  },
  listFavoriteProducts: {
    url: '/api/product/variant/favorites/',
  },
});
