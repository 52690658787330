import { coerceInt, coerceNum, coerceStr } from '@whop/utils/values';
import { coerce, coerceImage } from '@whop/core/mapping';

import {
  ProductColorModel,
  ProductColorPayload,
  ProductItemModel,
  ProductPayload,
  ProductPricesModel,
  ProductPricesPayload,
  ProductPromoModel,
  ProductPromoPayload,
  ProductSizeModel,
  ProductSizePayload,
  ProductTypeModel,
  ProductTypePayload,
} from './types';

function mapProductColor(variant: ProductColorPayload): ProductColorModel {
  const { variant_attrs } = variant;
  return {
    // name: coerceStr(variant.name),
    label: coerceStr(variant.label),
    variantId: String(variant.variant_id),
    mainImage: coerceImage(variant_attrs.main_image),
    url: variant_attrs.page_attributes?.url || '',
    prices: mapPrices(variant_attrs.prices),
    stock: variant_attrs.stock,
    externalStock: variant_attrs.extern_stock,
    multiColor: variant.multi_color,
    code: variant_attrs.code,
    variantAttrs: {
      labels: mapLabels(variant_attrs.labels_test),
    },
  };
}

function mapLabels(labels: ProductPayload['labels_test']): ProductItemModel['labels'] {
  return (labels || []).map((item) => {
    if (item.css_background) {
      return {
        code: item.code,
        label: item.label,
        variant: 'cssBackground',
        cssBackground: item.css_background,
      };
    }
    return {
      code: item.code,
      label: item.label,
      variant: 'defaultLabel',
    };
  });
}

function coerceMinAmountToOrder(val: number | null | undefined) {
  return val || 0;
}

function coerceMaxAmountToOrder(val: number | null | undefined) {
  // Note: for `null` payload it means "unlimited", for `0` it means "cannot order"
  return val ?? Number.MAX_SAFE_INTEGER;
}

function mapProductSize(variant: ProductSizePayload): ProductSizeModel {
  const { variant_attrs } = variant;
  const { common_params_test, statuses } = variant_attrs;
  return {
    label: coerceStr(variant.label),
    name: coerceStr(variant.name),
    dimensions: coerceStr(common_params_test.dimensions),
    variantId: String(variant.variant_id),
    mainImage: coerceImage(variant.variant_attrs.main_image),
    url: variant.variant_attrs.page_attributes?.url || '',
    prices: mapPrices(variant.variant_attrs.prices),
    stock: variant.variant_attrs.stock,
    externalStock: variant.variant_attrs.extern_stock,
    supplierStock: variant.variant_attrs.supplier_stock,
    code: coerceStr(variant.variant_attrs.code),
    statuses: variant.variant_attrs.statuses || {
      delivery_date: statuses?.delivery_date,
      supplier_delivery_date: statuses?.supplier_delivery_date,
    },
    minAmountToOrder: coerceMinAmountToOrder(common_params_test?.min_amount_to_order),
    maxAmountToOrder: coerceMaxAmountToOrder(common_params_test?.max_amount_to_order),
    variantAttrs: {
      labels: mapLabels(variant_attrs.labels_test),
      mainImage: coerceImage(variant_attrs.main_image),
    },
  };
}

function mapProductType(variant: ProductTypePayload): ProductTypeModel {
  return {
    label: coerceStr(variant.label),
    variantId: String(variant.variant_id),
    mainImage: coerceImage(variant.variant_attrs.main_image),
    url: variant.variant_attrs.page_attributes?.url || '',
    prices: mapPrices(variant.variant_attrs.prices),
    stock: variant.variant_attrs.stock,
    externalStock: variant.variant_attrs.extern_stock,
  };
}
function mapPrices(prices: ProductPricesPayload, mainPrice: number = 0): ProductPricesModel {
  return {
    original: coerceNum(prices.base_price_net, mainPrice),
    originalGross: coerceNum(prices.base_price_gross, mainPrice),
    discount: coerce.num(prices.discount_price_net, 0),
    discountGross: coerce.num(prices.discount_price_gross, 0),
    discountPct: coerceInt(prices.discount_percentage, 0),
  };
}

export function mapProductItem(payload: ProductPayload): ProductItemModel {
  const id = String(payload.id);
  const mainPrice = coerceNum(payload.prices.discount_price_net, 0);

  const { common_params_test } = payload;
  return {
    id,
    url: payload?.page_attributes?.url || '',

    name: coerceStr(payload.name),
    longName: coerceStr(payload.long_name),
    code: coerceStr(payload.code),

    mainPrice,
    prices: mapPrices(payload.prices, mainPrice),
    currency: coerceStr(payload),

    labels: mapLabels(payload.labels_test),
    inStock: coerceNum(payload.stock, 0),
    externalStock: coerceNum(payload.extern_stock, 0),

    minAmountToOrder: coerceMinAmountToOrder(common_params_test?.min_amount_to_order),
    maxAmountToOrder: coerceMaxAmountToOrder(common_params_test?.max_amount_to_order),

    // NotePrototype(pick name) is/flags/state
    is: {
      favorite: Boolean(payload.favorite),
      unavailable: Boolean(payload.is_unavailable),
    },
    options: {
      watchdogEnabled: true,
    },

    // ??? extra for rudorder

    mainImage: coerceImage(payload.main_image),
    images: payload.images ? payload.images.filter(Boolean).map(coerceImage) : [],

    params: payload.specific_params_test || {},
    shortDescriptionHtml: payload.short_description_html,
    descriptionHtml: payload.description_html,
    techDescriptionHtml: payload.tech_description_html,
    combinations: {
      sizes: payload.combinations_test.sizes.map(mapProductSize),
      colors: payload.combinations_test.colors.map(mapProductColor),
      types: payload.combinations_test.types.map(mapProductType),
    },
    brand: {
      name: payload.brand ? payload.brand.name : '',
      mainImage: coerceImage(payload.brand?.main_image),
    },
    specificParams: payload.specific_params_test || {},
    statuses: payload.statuses || {},
    customParams: payload.custom_params || {},
    commonParams: payload.common_params_test,
  };
}

export function mapProductPromo(payload: ProductPromoPayload): ProductPromoModel {
  return {
    ...payload,
    variants: payload.variants.map(mapProductItem),
  };
}
