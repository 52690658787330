import { RuntimeConfig } from '../app.types/app';
import { InitialDataModel } from '../app.base/initialData';

export async function loadRuntimeConfig(options: {
  initialData: InitialDataModel;
}): Promise<RuntimeConfig> {
  const { config } = options.initialData;
  const { pagination, price, registration } = config;
  return {
    pagination: {
      pageSize: pagination.default_page_size,
      pageSizePerResource: pagination.default_page_sizes,
      defaultMode: 'autoloadWithFallback',
    },
    price: {
      locale: price.locale,
      currency: price.currency,
      minimumFractionDigits: price.minimum_fraction_digits,
      maximumFractionDigits: price.maximum_fraction_digits,
    },
    registration: {
      mode: registration.registration_mode,
      defaultRegisteredUserActive: registration.default_active_registered_user,
    },
    partnerIdPricesEnabled: config.partner_id_prices,
    //@TODO - read from initial data when possible
    //initial data should also have a list of possible options
    productListingType: 'grid',
  };
}
