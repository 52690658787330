import { Container } from '@whys/app/lib/state';
import { StaticPageNames, SystemPageNames } from '@whop/niceurls/types';
import { InitialDataModel } from '../app.base/initialData';
import { AppResourceContext } from '../app.types/state';
import { httpResources as niceurlsHttpResources } from '@whop/niceurls';
import { httpResources as languageUrlsHttpResources } from '@whop/core';
import { defineSelector } from '../tmp.prototyping/selector';
import { ProjectIdNames } from '@whop/core/types';
import { readCookie, writeCookie } from '@whop/browser/cookies';
import { UserPermissionsNames } from '@whop/user/types';
import { PlainResource } from '@whop/resources/types';
import { definePlainAppNullableResource } from '../tmp.prototyping/appLevelResources';
import { AppLanguageEnum } from '../app.types/app';
import { SoftwareModule } from '@whop/core/types.initialdata';

type SystemPageModel = InitialDataModel['system_pages'];
type SystemPagePayload = InitialDataModel['system_pages'];
function mapSystemPage(payload: SystemPagePayload): SystemPageModel {
  return payload;
}

type StaticPageModel = InitialDataModel['static_pages'];
type StaticPagePayload = InitialDataModel['static_pages'];
function mapStaticPage(payload: StaticPagePayload): StaticPageModel {
  return payload;
}

type LanguageChangePayload = { redirect_url?: string };

type CustomUserPermissionNames = 'xReadCart';

const resources = {
  ...niceurlsHttpResources,
  ...languageUrlsHttpResources,
};

type LocalState = {
  showCookiesPopup: boolean;
};

type LocalProps = {
  initialData: InitialDataModel;
  resourceContext: AppResourceContext;
  projectId: ProjectIdNames;
  language: AppLanguageEnum;
};

export class GlobalContainer extends Container<LocalState> {
  private state: LocalState;

  systemPages: PlainResource<SystemPageModel, null>;
  staticPages: PlainResource<StaticPageModel, null>;

  select = defineSelector({
    operator: () => this.props.initialData.operator,
    phoneNumber: () => this.props.initialData.operator.primary_phone_number,
    openingHours: () => this.props.initialData.operator.opening_hours,
    claim: () => this.props.initialData.site.claim,
    defaultPageTitle: () => this.props.initialData.site.page_title,
    siteName: () => this.props.initialData.site.name,
    softwaremodules: () => this.props.initialData.softwaremodules,
  });

  constructor(private props: LocalProps) {
    super();

    const { initialData, resourceContext } = props;

    this.systemPages = definePlainAppNullableResource(resources.systemPages, {
      resourceContext,
      initialValue: initialData.system_pages,
      map: mapSystemPage,
    });

    this.staticPages = definePlainAppNullableResource(resources.staticPages, {
      resourceContext,
      initialValue: initialData.static_pages,
      map: mapStaticPage,
    });
    this.state = {
      showCookiesPopup: false,
    };
  }

  /**
   * A simple helper because system pages needs only urls.
   */
  systemPageUrl(name: SystemPageNames): string {
    return this.systemPageInfo(name).url;
  }

  staticPageUrl(name: StaticPageNames): string {
    return this.staticPageInfo(name).url;
  }

  async staticAnyPageUrl(identifier: string): Promise<string> {
    return (await this.staticAnyPageInfo(identifier)).url;
  }

  systemPageInfo(pageName: SystemPageNames): { name: string; url: string } {
    const infos = this.systemPages.select();
    const pageInfo = infos && infos[pageName];
    if (pageInfo) {
      return pageInfo;
    }
    return { name: '', url: '' };
  }

  staticPageInfo(pageName: StaticPageNames): { name: string; url: string } {
    const infos = this.staticPages.select();
    const pageInfo = infos && infos[pageName];
    if (pageInfo) {
      return pageInfo;
    }
    return { name: '', url: '' };
  }

  async staticAnyPageInfo(identifier: string): Promise<{ name: string; url: string }> {
    const { __fetchJson } = this.props.resourceContext;
    const result = await __fetchJson<{ url: string; name: string }, object, object>(
      resources.staticAnyPageInfo(identifier)
    );

    if (result.status === 'ok') {
      return result.data;
    }

    return { name: '', url: '' };
  }

  /**
   * Determines whether user has specified permissions
   * @review should we move this to runtime config? (ConfigContainer)
   */
  hasPermissionTo(...perms: Array<UserPermissionsNames>): boolean {
    const { permissions } = this.props.initialData;
    return perms.map((p) => permissions[p]).every((isAllowed) => isAllowed === true);
  }

  hasCustomPermission(custom: CustomUserPermissionNames) {
    return matchEachToBoolean(custom, {
      xReadCart: () => {
        // @review ... how to handle this
        return this.hasPermissionTo('create_order');
      },
    });
  }

  //
  // Cookie consent
  //

  // Note(cookies_confirmed): privacy policy mentions this key, keep it
  cookieConfirmKey = 'cookies_confirmed';
  cookieConfirmValue = '1';

  acceptCookies() {
    writeCookie(this.cookieConfirmKey, this.cookieConfirmValue, { expireInDays: 365 });
  }

  selectCookiesAccepted(): boolean {
    return readCookie(this.cookieConfirmKey) === this.cookieConfirmValue;
  }

  acceptCookiesPopup() {
    writeCookie(this.cookieConfirmKey, this.cookieConfirmValue, { expireInDays: 365 });
  }

  // eslint-disable-next-line
  storeCookiesConsent(consent: any) {
    writeCookie('cookie_consent_essential', 'true');
    writeCookie('cookie_consent_analytics', `${consent.cookie_consent_analytics}`);
    writeCookie('cookie_consent_remarketing', `${consent.cookie_consent_remarketing}`);
  }

  getCookiesConsent() {
    return {
      cookie_consent_essential: true,
      cookie_consent_analytics: readCookie('cookie_consent_analytics'),
      cookie_consent_remarketing: readCookie('cookie_consent_remarketing'),
    };
  }

  showCookiesPopup(): boolean {
    return readCookie(this.cookieConfirmKey) === this.cookieConfirmValue;
  }

  getOpenCookiesPopup(): boolean {
    return this.state.showCookiesPopup;
  }

  setOpenCookiesPopup(show: boolean) {
    return this.setState({ showCookiesPopup: show });
  }

  /**
   * DO NOT USE THIS in production code. Use it only for temporary code.
   * Every custom code should be handled via theme or component registry.
   */
  __isSpecificProject(projectName: ProjectIdNames) {
    return this.selectProjectId() === projectName;
  }

  selectProjectId() {
    return this.props.projectId;
  }

  selectLanguage(): AppLanguageEnum {
    return this.props.language;
  }

  selectLocale() {
    return this.props.initialData.config.price.locale;
  }

  /**
   * This changes the app's language. Page reload is necessary (@review or is it?).
   * @param language what language should be used
   * @param opts.fromUrl current location
   * @returns redirectUrl - fromUrl transformed with new language e.g. - /en/about => /de/uber
   */
  async changeActiveLanguage(
    language: AppLanguageEnum,
    opts: { fromUrl: string }
  ): Promise<{ redirectUrl?: string }> {
    const { __fetchJson } = this.props.resourceContext;
    const result = await __fetchJson<LanguageChangePayload, object, object>(
      resources.changeLanguage,
      {
        language_code: language,
        url: opts.fromUrl,
      }
    );
    if (result.status === 'ok') {
      const { data } = result;
      return { redirectUrl: data.redirect_url };
    }
    return { redirectUrl: undefined };
  }

  getAvailableLanguages() {
    return this.props.initialData.available_languages;
  }

  isSoftwaremoduleActive(code: string): boolean {
    if (!this.props.initialData.softwaremodules) return false;
    return this.props.initialData.softwaremodules.some(
      (softwaremodule) => softwaremodule.code === code
    );
  }

  getSoftwaremodule(code: string): SoftwareModule | undefined {
    if (!this.props.initialData.softwaremodules) return undefined;
    return this.props.initialData.softwaremodules.find(
      (softwaremodule) => softwaremodule.code === code
    );
  }

  getSoftwaremodules(): SoftwareModule[] {
    return this.props.initialData.softwaremodules;
  }
}

function matchEachToBoolean<T extends string>(value: T, cases: Record<T, () => boolean>) {
  return cases[value]();
}

export type GlobalContainerType = GlobalContainer;
