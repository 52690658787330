import React, { FC, useState } from 'react';
import { HeaderItemModel, MenuItemModel } from '@whop/menu/types';
import { systemUI } from '../utils.styles/system';
import { css } from '@emotion/core';
import { ExpandButton } from '../tsx.design/ExpandButton';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { useLingui } from '../app.use/useLingui';
import { Collapse } from '@material-ui/core';
import { StackList } from '../utils.styles/StackList';
import { BlockLink, TextLink } from '../tsx.components/links';
import { useTheme } from '../app.use/useTheme';
import { useHasMounted, useManualEffect } from '@whop/react/component';
import { useLocation } from 'react-router-dom';
import { SidemenuLayout } from '../pkg.theme/types';
import { Location } from 'history';
import { PopupCategoryMenuWrapper } from '../tsx.app.category/PopupCategoryMenu';
import { fade } from '@material-ui/core/styles/colorManipulator';

// ! if we're in this file/component, we're in the sidemenu layout
// * so no checks for that are needed

type VerticalMenuItemModel = {
  id: string;
  title: string;
  url: string;
  icon?: string;
  children: VerticalMenuItemModel[];
};

const ICON_SIZE = '20px';

const MenuItemContent = (props: { item: VerticalMenuItemModel }) => {
  const { item } = props;

  const theme = useTheme();
  const { useGlyphicons = false } = theme.layout as SidemenuLayout;

  const icon = item.icon;

  if (useGlyphicons) {
    return (
      <systemUI.FlexRow>
        {icon ? (
          <img src={icon} alt="item icon" style={{ width: ICON_SIZE }} />
        ) : (
          <systemUI.Box style={{ width: ICON_SIZE }} />
        )}
        <systemUI.HSpace />
        {item.title}
      </systemUI.FlexRow>
    );
  }

  return <span>{item.title}</span>;
};

const CollapseContent = (props: {
  item: VerticalMenuItemModel;
  expanded: boolean;
  isActive: (s: string) => boolean;
}) => {
  const { item, expanded, isActive } = props;

  const theme = useTheme();

  const { useExpansion = false } = theme.layout as SidemenuLayout;

  const hasChildren = !!item.children.length;

  if (!useExpansion) return null;

  if (!hasChildren) return null;

  return (
    <Collapse in={expanded}>
      <StackList className="collapsed-menu" system={{ pX: [2], pY: [2] }} space={[0]}>
        {item.children.map((item) => (
          <BlockLink
            key={item.id}
            to={item.url}
            label={item.title}
            css={css`
              color: ${theme.colors.text_contrast};
              &:hover {
                color: ${theme.colors.text_contrast};
                text-decoration: underline;
              }
              ${isActive(item.url)
                ? `
                  font-weight: bold;
                  color: ${fade(theme.colors.text_contrast, 1)};
                  margin-left: -2px;
                  `
                : ``}
            `}
            style={{ padding: '2px 0 2px 2px', opacity: '100%' }}
          >
            {item.title}
          </BlockLink>
        ))}
      </StackList>
    </Collapse>
  );
};

function useSideMenuItemCss() {
  return useTheme().override.globalStyles.sideMenuItem;
}

function useSideMenuCss() {
  return useTheme().override.globalStyles.sideMenu;
}
function useSpecialSideMenuCss() {
  return useTheme().override.globalStyles.specialSideMenu;
}

const MenuItemWrapper: FC<{
  onExpand: () => void;
  redirectUrl: string;
}> = (props) => {
  const { children, onExpand, redirectUrl } = props;
  const theme = useTheme();
  const sideMenuItemCss = useSideMenuItemCss();
  const { useExpansion = false } = theme.layout as SidemenuLayout;

  if (useExpansion) {
    return (
      <div css={sideMenuItemCss} onClick={onExpand}>
        {children}
      </div>
    );
  }

  return (
    <BlockLink extendedCss={sideMenuItemCss} label="" to={redirectUrl}>
      {children}
    </BlockLink>
  );
};

function VerticalMenuItem(props: {
  item: VerticalMenuItemModel;
  color: string;
  expanded: boolean;
  onExpand: () => void;
  markActive: boolean;
  location: Location;
}) {
  const { item, expanded, markActive, location } = props;
  const i18n = useLingui();
  const hasChildren = !!item.children.length;
  const hasUrl = !!item.url;
  const isActive = (itemURL: string) => markActive && itemURL === location.pathname;
  const theme = useTheme();
  const { useExpansion = false } = theme.layout as SidemenuLayout;

  const renderItem = () => {
    if (hasUrl) {
      return (
        <TextLink
          css={css`
            width: ${hasChildren ? 'initial' : '100%'};
            font-weight: ${isActive(item.url) ? 'bold' : undefined};
            color: ${isActive(item.url)
              ? fade(theme.colors.text_contrast, 1)
              : theme.colors.text_contrast};
            &:hover {
              text-decoration: underline;
              color: ${isActive(item.url)
                ? fade(theme.colors.text_contrast, 1)
                : theme.colors.text_contrast};
            }
          `}
          to={item.url}
        >
          <MenuItemContent item={item} />
        </TextLink>
      );
    }

    return (
      <div
        css={css`
          color: ${theme.colors.text_contrast};
        `}
      >
        <MenuItemContent item={item} />
      </div>
    );
  };

  return (
    <MenuItemWrapper redirectUrl={item.url} onExpand={() => hasChildren && props.onExpand()}>
      <PopupCategoryMenuWrapper
        activeMenuItem={item as MenuItemModel}
        placement="right"
        customWidth="600px"
        fullspan={true}
      >
        {(injectedProps) => {
          return (
            <systemUI.FlexRow
              system={{
                paddingLeft: [2],
                paddingRight: [2],
                fontWeight: expanded ? ['bold'] : ['normal'],
                color: ['text_contrast'],
              }}
              css={css`
                box-sizing: border-box;
                width: 100%;
                min-height: 45px;
                cursor: pointer;
                background: ${props.color};
                justify-content: space-between;
              `}
              onClick={injectedProps.forceClose}
              onMouseEnter={() =>
                injectedProps.onCategoryMouseEnter(item as MenuItemModel, hasChildren)
              }
              onMouseLeave={injectedProps.onCategoryMouseLeave}
            >
              {renderItem()}
              {useExpansion && hasChildren && (
                <ExpandButton
                  label={i18n.t`Expand`}
                  expanded={expanded}
                  style={{ color: theme.colors.text_contrast, padding: '0px', marginRight: '5px' }}
                  color={'inherit'}
                />
              )}
              {!useExpansion && hasChildren && (
                <ExpandIcon
                  fontSize="large"
                  css={css`
                    transform: rotate(270deg);
                  `}
                />
              )}
            </systemUI.FlexRow>
          );
        }}
      </PopupCategoryMenuWrapper>

      <CollapseContent item={item} expanded={expanded} isActive={isActive} />
    </MenuItemWrapper>
  );
}

function BasicMenu(props: {
  items: VerticalMenuItemModel[];
  markActive: boolean;
  color: string;
  location: Location;
  expandedItems: { [id: string]: boolean };
  special?: boolean;
}) {
  const { items, location, special = false } = props;

  const sideMenuCss = useSideMenuCss();
  const specialSideMenuCss = useSpecialSideMenuCss();

  const [expandedItems, setExpandedItems] = useState(props.expandedItems);

  React.useEffect(() => {
    setExpandedItems(props.expandedItems);
  }, [props.expandedItems]);

  const expandItem = (id: string) => {
    setExpandedItems((items) => {
      return { ...items, [id]: !items[id] };
    });
  };

  return (
    <div
      css={css`
        ${sideMenuCss};
        ${special ? specialSideMenuCss : null}
      `}
    >
      <StackList space={['quarter']}>
        {items.map((item) => (
          <VerticalMenuItem
            key={item.id}
            item={item}
            color={props.color}
            expanded={expandedItems[item.id]}
            onExpand={() => expandItem(item.id)}
            markActive={props.markActive}
            location={location}
          />
        ))}
      </StackList>
    </div>
  );
}

/**
 * Menu for mobile versions of shops
 *
 * @param onRedirect
 * Callback func. which gets called when router has changed location.
 * Works even when going from '/' (homepage) back to '/'
 */
export function VerticalPopupMenu(props: {
  menuItems: MenuItemModel[];
  headerItems: HeaderItemModel[];
  onRedirect?: () => void;
  markActive?: boolean;
  expandedItems?: { [id: string]: boolean };
}) {
  const { onRedirect, markActive = false, expandedItems = {} } = props;
  const theme = useTheme();
  const hasMounted = useHasMounted();
  const location = useLocation();
  const sideMenuCss = useSideMenuCss();

  useManualEffect(() => {
    if (hasMounted && onRedirect) {
      onRedirect();
    }
  }, [location]);

  return (
    <nav
      css={css`
        height: 100%;
        width: 100%;
        overflow: auto;
        //overscroll-behavior: contain;
        ${sideMenuCss}
      `}
    >
      <BasicMenu
        color={theme.colors.primary}
        markActive={markActive}
        items={props.menuItems}
        location={location}
        expandedItems={expandedItems}
      />

      <div css={sideMenuCss} className="menus-divider">
        <systemUI.VSpace size={['quarter']} />
      </div>

      <BasicMenu
        color={theme.colors.primary}
        markActive={markActive}
        items={props.headerItems}
        location={location}
        expandedItems={expandedItems}
        special
      />
    </nav>
  );
}
