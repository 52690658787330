/**
 * A consistency helper to define which components are used on current page or view.
 *
 * No that this cannot be used lower in component tree, you should pass the specific component
 * in a prop instead.
 */

import { AsyncRegistryShape, UsedComponentsInfo } from './types';

// @review: unsure how to type values correcly (it can be opaque/untyped in call site)

export function declareUsedComponents<T extends Record<string, keyof AsyncRegistryShape>>(
  declaration: T
): UsedComponentsInfo<T> {
  const all = Array.from(Object.values(declaration));
  return {
    ...declaration,
    all,
  };
}
