import { coerce } from '@whop/core/mapping';
import { ProfilePayload, ProfileModel } from './types';

export function mapProfile(payload: ProfilePayload): ProfileModel {
  /** Due to databases-switching, profile (payload) can be null.
   * Thats why we have to manually check it to ensure that resource such as orderContainer.orderDetail(id)
   * doesnt return null value as well just because profile is null.
   * Access to the pages is handled by back-end.
   *
   * ? There might be a better solution but this one works.
   */
  return {
    id: payload ? String(payload.id) : '',
    firstName: payload ? coerce.str(payload.first_name) : '',
    lastName: payload ? coerce.str(payload.last_name) : '',
    phoneNumber: payload ? coerce.str(payload.phone_number) : '',
    mobileNumber: payload ? coerce.str(payload.mobile_number) : '',
    title: payload ? coerce.str(payload.title) : '',
    customerId: payload ? coerce.str(payload.customer_id) : '',
    email: payload ? coerce.str(payload.email) : '',
  };
}
