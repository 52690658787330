import React, { createContext, useContext } from 'react';
import { StaticRegistryModel } from './types';

const ctx = createContext<StaticRegistryModel | null>(null);

export function useInternalCtx(): StaticRegistryModel {
  const val = useContext(ctx);
  if (!val) {
    throw new Error('Please use StaticRegistryProvider.');
  }
  return val;
}

export function StaticRegistryProvider(props: {
  children: React.ReactElement;
  value: StaticRegistryModel;
}) {
  return <ctx.Provider value={props.value}>{props.children}</ctx.Provider>;
}
