import { useRef } from 'react';
import { useUnmount } from './component';

type ManagerOptions<TKeys> = { timeoutMs: number; id: TKeys };

type TimersManager<TKeys> = {
  set: (opts: ManagerOptions<TKeys>, fn: Function) => void;
  // basically debounce
  reset: (opts: ManagerOptions<TKeys>, fn: Function) => void;
  clearById: (id: TKeys) => void;
  clearAll: () => void;
};

function createManager<TKeys>(): TimersManager<TKeys> {
  const timersMap = new Map();

  return {
    set: (opts: ManagerOptions<TKeys>, fn: Function) => {
      const { id, timeoutMs } = opts;
      const curTimeouts = timersMap.get(id);
      if (curTimeouts) {
        timersMap.set(id, [...curTimeouts, setTimeout(fn, timeoutMs)]);
      } else {
        timersMap.set(id, [setTimeout(fn, timeoutMs)]);
      }
    },
    reset: (opts: ManagerOptions<TKeys>, fn: Function) => {
      const { id, timeoutMs } = opts;
      const curTimeouts = timersMap.get(id);
      for (const timerId of curTimeouts || []) {
        clearTimeout(timerId);
      }
      timersMap.set(id, [setTimeout(fn, timeoutMs)]);
    },
    clearById: (id: TKeys) => {
      const timer = timersMap.get(id);
      if (!timer) {
        return;
      }
      timer.forEach(clearTimeout);
    },
    clearAll: () => {
      timersMap.forEach((curTimeouts) => {
        for (const timerId of curTimeouts) {
          clearTimeout(timerId);
        }
      });
      timersMap.clear();
    },
  };
}

type GenericsRequired = never;

/**
 * Provides timers manager to correctly use setTimeout/clearTimeout as hook.
 * timers.reset can be used to "debounce"
 * timers.set can be used to "delay"
 */
export function useTimers<TKeys = GenericsRequired>(): TimersManager<TKeys> {
  let managerRef = useRef<TimersManager<TKeys> | void>();
  if (!managerRef.current) {
    managerRef.current = createManager<TKeys>();
  }
  const manager = managerRef.current;
  useUnmount(() => {
    manager.clearAll();
  });
  return manager;
}
