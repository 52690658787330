import { useAppContainer, useOnResetApp } from '../app.context/frameworkContext';
import { ViewContext } from '../pkg.views/types';

export function useDjReactState() {
  const appContainer = useAppContainer();
  return appContainer.context.djreactState;
}

export function useFetchJson() {
  const appContainer = useAppContainer();
  return appContainer.context.fetchJson;
}

export function useEnvironment(): 'production' | 'development' | 'test' {
  const djreactState = useDjReactState();
  return djreactState.env;
}

export function useResetAppFn() {
  return useOnResetApp();
}

function useViewContextFn(): () => ViewContext {
  const appContainer = useAppContainer();
  return () => {
    return {
      appContainer,
    };
  };
}

export function useViewContext(): ViewContext {
  return useViewContextFn()();
}

useViewContext.fn = useViewContextFn;

export { useAppContainer };
