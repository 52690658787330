import * as React from 'react';
import { css as emotionCss } from '@emotion/core';

import { useTheme } from '../app.use/useTheme';
import { SystemBox } from '@whop/system';
import { useMemo } from 'react';
import { SystemPropType, ResponsiveValues } from '@whop/system/types';
import { OverrideableHtmlTags } from '../pkg.theme/types';

/**
 * @overview This module provides helper components to use HTML tags without implicit
 * browser styles.
 *
 * Its like a CSS reset, but not applied globally.
 */

// NotePrototype: global type?
type CssObject = { [k: string]: string | number };
type AsPropType = OverrideableHtmlTags;

type SystemHtmlProps<T extends AsPropType, P = React.ComponentProps<T>> = {
  as?: T;
  system?: SystemPropType;
  responsive?: { [prop: string]: ResponsiveValues<number | string> };
  className?: string;
  css?: AnyProtoTypeLater;
  children?: React.ReactNode;
} & P;

// @review: add forwardRef support
function createSystemHtml<T extends AsPropType>(config: { resetCss?: CssObject; as: T }) {
  const { as, resetCss } = config;

  function SystemHtml(props: SystemHtmlProps<T>) {
    const theme = useTheme();
    const { css, ...pass } = props;
    const globalCss = theme.override.html[as || ''];

    // @review: dunno why the order works like that
    // @review: remove useMemo fully or add a module-level caching
    const cssOverride = useMemo(() => {
      return emotionCss([resetCss, globalCss, css]);
    }, [globalCss, css]);

    // @ts-ignore (UnreadableErrorMessage)
    return <SystemBox as={as} {...pass} css={cssOverride} />;
  }
  SystemHtml.displayName = `SystemHtml(${as})`;
  return SystemHtml;
}

export const li = createSystemHtml({
  as: 'li',
  resetCss: {
    margin: 0,
  },
});

const ulOlResetCss = {
  padding: '0',
  margin: '0',
  listStyleType: 'none',
  paddingInlineStart: 0,
};

export const ul = createSystemHtml({
  as: 'ul',
  resetCss: ulOlResetCss,
});

export const ol = createSystemHtml({
  as: 'ol',
  resetCss: ulOlResetCss,
});

export const strong = createSystemHtml({ as: 'strong', resetCss: {} });

export const hr = createSystemHtml({
  as: 'hr',
  resetCss: {},
});

export const p = createSystemHtml({
  as: 'p',
  resetCss: {},
});

export const em = createSystemHtml({
  as: 'em',
  resetCss: {},
});

export const html = {
  li,
  ul,
  ol,
  strong,
  hr,
  p,
  em,
};

//
// Internal only BELOW
//

// Note: do not use these heading components. Use the Heading component instead.

const headingResetCss = {
  fontSize: 'inherit',
  fontWeight: 'normal',
  margin: '0',
  marginBlockEnd: '0',
  marginBlockStart: '0',
  marginInlineEnd: '0',
  marginInlineStart: '0',
};

const h1 = createSystemHtml({
  as: 'h1',
  resetCss: headingResetCss,
});

const h2 = createSystemHtml({
  as: 'h2',
  resetCss: headingResetCss,
});

const h3 = createSystemHtml({
  as: 'h3',
  resetCss: headingResetCss,
});

const h4 = createSystemHtml({
  as: 'h4',
  resetCss: headingResetCss,
});

const h5 = createSystemHtml({
  as: 'h5',
  resetCss: headingResetCss,
});

const h6 = createSystemHtml({
  as: 'h6',
  resetCss: headingResetCss,
});

export const __headingComponents = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
};
