import React from 'react';
import { createContext } from 'react';
import { I18nProvider } from '@lingui/react';

import { I18nType } from '../app.types/npm';

export const LinguiContext = createContext<I18nType | null>(null);

export function LinguiProvider({
  value,
  children,
}: {
  value: I18nType;
  children: React.ReactNode;
}) {
  // @ts-ignore: really dunno what to pass to the I18nProvider
  const catalogs = value._catalogs;
  return (
    <LinguiContext.Provider value={value}>
      <I18nProvider language={value.language} catalogs={catalogs}>
        {children}
      </I18nProvider>
    </LinguiContext.Provider>
  );
}
