import {
  AsyncRegistryManager,
  RegistryComponentNames,
  AsyncRegistryShape,
  UsedComponentsInfo,
} from './types';

export function createAsyncRegistryManager(registry: AsyncRegistryShape): AsyncRegistryManager {
  async function preload(...keys: Array<RegistryComponentNames>) {
    await Promise.all(keys.map((name) => registry[name].preload()));
  }

  async function preloadDeclared<T>(info: UsedComponentsInfo<T>) {
    await preload(...info.all);
  }

  async function fetch<TKey extends RegistryComponentNames>(
    key: TKey
  ): Promise<AsyncRegistryShape[TKey]> {
    // @ts-ignore
    return await registry[key].load();
  }

  return {
    registry,
    preload,
    preloadDeclared,
    fetch,
  };
}
